import { library, IconPack } from '@fortawesome/fontawesome-svg-core'; // Core SVG
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; // Integration
import {
  faClock,
  faHourglassEnd,
  faEdit,
  faTrash,
  faArchive,
  faCaretLeft,
  faCaretDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faBuilding,
  faMapMarkerAlt,
  faFileContract,
  faBookmark,
  faSearch,
  faTimesCircle,
  faCalendarAlt,
  faMicroscope,
  faIdCardAlt,
  faLink,
  faGlobe,
  faExternalLinkAlt,
  faCircle,
  faCopy,
  faCog,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faUndo,
  faRedo,
  faListOl,
  faListUl,
  faQuoteLeft,
  faLevelDownAlt,
  faMinus,
  faParagraph,
  faExclamationTriangle,
  faFileAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { CoreIconsProvider } from 'core-icons';
import { CoreLoginPanelProvider } from 'core-login-panel';

library.add(
  faClock as unknown as IconPack,
  faHourglassEnd as unknown as IconPack,
  faEdit as unknown as IconPack,
  faTrash as unknown as IconPack,
  faArchive as unknown as IconPack,
  faCaretLeft as unknown as IconPack,
  faCaretDown as unknown as IconPack,
  faChevronRight as unknown as IconPack,
  faChevronLeft as unknown as IconPack,
  faChevronUp as unknown as IconPack,
  faBuilding as unknown as IconPack,
  faMapMarkerAlt as unknown as IconPack,
  faFileContract as unknown as IconPack,
  faBookmark as unknown as IconPack,
  faSearch as unknown as IconPack,
  faTimesCircle as unknown as IconPack,
  faCalendarAlt as unknown as IconPack,
  faMicroscope as unknown as IconPack,
  faIdCardAlt as unknown as IconPack,
  faLink as unknown as IconPack,
  faGlobe as unknown as IconPack,
  faExternalLinkAlt as unknown as IconPack,
  faCircle as unknown as IconPack,
  faCopy as unknown as IconPack,
  faCog as unknown as IconPack,
  faBold as unknown as IconPack,
  faItalic as unknown as IconPack,
  faUnderline as unknown as IconPack,
  faAlignLeft as unknown as IconPack,
  faAlignRight as unknown as IconPack,
  faAlignCenter as unknown as IconPack,
  faAlignJustify as unknown as IconPack,
  faUndo as unknown as IconPack,
  faRedo as unknown as IconPack,
  faListOl as unknown as IconPack,
  faListUl as unknown as IconPack,
  faMinus as unknown as IconPack,
  faQuoteLeft as unknown as IconPack,
  faLevelDownAlt as unknown as IconPack,
  faParagraph as unknown as IconPack,
  faExclamationTriangle as unknown as IconPack,
  faFileAlt as unknown as IconPack,
  faInfoCircle as unknown as IconPack
);

const icons = {
  cog: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'cog']
    }
  },
  copy: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'copy']
    }
  },
  circle: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'circle']
    }
  },
  externalLinkAlt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'external-link-alt']
    }
  },
  timesCircle: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'times-circle']
    }
  },
  globe: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'globe']
    }
  },
  search: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'search']
    }
  },
  bookmark: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'bookmark']
    }
  },
  fileContract: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'file-contract']
    }
  },
  clock: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'clock']
    }
  },
  hourglassEnd: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'hourglass-end']
    }
  },
  edit: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'edit']
    }
  },
  trash: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'trash']
    }
  },
  archive: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'archive']
    }
  },
  caretLeft: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'caret-left']
    }
  },
  caretDown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'caret-down']
    }
  },
  chevronRight: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'chevron-right']
    }
  },
  chevronLeft: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'chevron-left']
    }
  },
  chevronUp: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'chevron-up']
    }
  },
  building: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'building']
    }
  },
  mapMarkerAlt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'map-marker-alt']
    }
  },
  calendarAlt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'calendar-alt']
    }
  },
  microscope: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'microscope']
    }
  },
  idCardAlt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'id-card-alt']
    }
  },
  link: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'link']
    }
  },
  bold: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'bold']
    }
  },
  italic: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'italic']
    }
  },
  underline: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'underline']
    }
  },
  alignLeft: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'align-left']
    }
  },
  alignRight: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'align-right']
    }
  },
  alignCenter: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'align-center']
    }
  },
  alignJustify: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'align-justify']
    }
  },
  undo: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'undo']
    }
  },
  redo: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'redo']
    }
  },
  bulletList: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'list-ul']
    }
  },
  orderList: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'list-ol']
    }
  },
  horizontalRule: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'minus']
    }
  },
  hardBreak: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'level-down-alt']
    }
  },
  blockQuote: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'quote-left']
    }
  },
  paragraph: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'paragraph']
    }
  },
  exclamationTriangle: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'exclamation-triangle']
    }
  },
  fileAlt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'file-alt']
    }
  },
  infoCircle: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'info-circle']
    }
  }
};

CoreIconsProvider.mergeIcons(icons);
CoreLoginPanelProvider.mergeIcons(icons);

export default icons;
