import { RouteConfig } from 'vue-router';
import { AuthGuard } from '@/modules/core/core-auth';
import RouterModuleInterface from '@/router/RouterModuleInterface';
import { RoutesNames, RoutesPaths } from '@/router/offers/types';

const OpLayout = () => import('@/views/Layouts/OpLayout.vue');
const AdminEditOfferView = () => import('@/views/AdminPanel/AdminOffers/AdminEditOfferView.vue');
const EmptyLayout = () => import('@/views/Layouts/EmptyLayout.vue');
const OfferView = () => import('@/views/Offer/OfferView.vue');
const E404View = () => import('@/views/Globals/E404View.vue');
const AdminLayout = () => import('@/views/Layouts/AdministrationLayout.vue');

export default class OfferRoutes implements RouterModuleInterface {
  module(authGuard?: AuthGuard): RouteConfig {
    return {
      path: '/offer',
      component: EmptyLayout,
      children: [
        {
          path: '',
          component: E404View
        },
        {
          path: ':uuid',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: E404View
            },
            {
              path: RoutesPaths.VIEW,
              component: OpLayout,
              children: [
                {
                  path: '',
                  name: RoutesNames.VIEW,
                  component: OfferView
                }
              ]
            },
            {
              path: RoutesPaths.EDIT,
              component: AdminLayout,
              children: [
                {
                  path: '',
                  name: RoutesNames.EDIT,
                  component: AdminEditOfferView,
                  beforeEnter: async (to, from, next) => {
                    await authGuard?.continueIfAuthenticated(to, from, next);
                  }
                }
              ]
            }
          ]
        }
      ]
    };
  }
}
