import { ParsedDictionary } from '@/modules/core/core-dynamic-dictionaries';
import { PwrFile } from '@/components/Pwr/PwrFiles/types';
import {ActivityLog} from "@/types";

// TODO use core-dynamic-dictionaries
// That library needs to be upgraded
export enum OfferStates {
  DRAFT = 1,
  PUBLIC = 2,
  WAITING_FOR_CONCLUSION = 3,
  CONCLUDED = 4,
  ARCHIVE = 5,
  SCHEDULED = 7
}

interface Offer {
  uuid?: string;

  additionalInfo?: string;
  description?: string;
  abstract?: string;
  documentsSubmissionDeadline?: string;
  eRecruiter?: string;
  footer?: string;
  referenceNumber?: string;
  requiredDocuments?: string;
  position?: string;
  rodo?: string;
  tasks?: string;
  scienceDiscipline?: string;
  specialization?: string;
  weRequire?: string;
  weOffer?: string;

  city?: number;
  contractType?: number;
  type?: number;
  unit?: number;
  state?: number | OfferStates;
  workingTime?: number;

  createdAt?: string;
  expirationDate?: string;
  publicationDate?: string;
  termOfEmployment?: string;
  readinessContestSettlementDate?: string;

  isContest?: boolean;

  updatedAt?: string;

  files?: PwrFile[];

  fallbackDict?: ParsedDictionary;

  activity?: ActivityLog;
}

export const dateTimeFields: (keyof Offer)[] = [
  'createdAt',
  'documentsSubmissionDeadline',
  'expirationDate',
  'publicationDate',
  'termOfEmployment',
  'readinessContestSettlementDate',
  'updatedAt'
];

export const dictFields: (keyof Offer)[] = [
  'city',
  'contractType',
  'type',
  'unit',
  'state',
  'workingTime'
];

export const textFields: (keyof Offer)[] = [
  'abstract',
  'additionalInfo',
  'description',
  'eRecruiter',
  'footer',
  'referenceNumber',
  'requiredDocuments',
  'position',
  'rodo',
  'tasks',
  'scienceDiscipline',
  'specialization',
  'weRequire',
  'weOffer'
];

export default Offer;
