import { isNumber, isString } from 'lodash';
import { Commit, Dispatch } from 'vuex';
import { DictionariesService } from '@/modules/core/core-dynamic-dictionaries';
import { ErrorHelper } from '@/modules/core/core-helpers';
import Offer, { OfferStates } from '@/models/Offer';
import { Dictionary } from '@/types';
import { ActionTypes, FetchAction, MutationTypes, OffersState } from '@/store/offers/types';
import OfferService from '@/services/offers/OffersService';

export default class OffersStoreService {
  public static processByQuery(offersSource: Offer[], query: string): Offer[] {
    if (query) {
      return offersSource.filter((offer: Offer) => {
        let keys = Object.keys(offer);
        const skip = ['softDeleted', 'uuid', 'state', 'rodo'];

        keys = keys.filter((key: string) => !skip.includes(key));

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = offer[key as keyof Offer];

          if (isNumber(value)) {
            const parsedValue = DictionariesService.getLabel(`offer.${key}.${value}`);
            if (parsedValue && parsedValue.toLowerCase().search(query.toLowerCase()) >= 0) {
              return true;
            }
          }

          if (isString(value)) {
            if (value.toLowerCase().search(query.toLowerCase()) >= 0) {
              return true;
            }
          }
        }

        return false;
      });
    }

    return [];
  }

  public static processByFilters(offersSource: Offer[], scope: Dictionary<number[]>): Offer[] {
    if (scope) {
      const keys = Object.keys(scope);

      return offersSource.filter((offer: Offer) => {
        const result = [];

        // Allows to get AND result with filters

        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const selectedFilters = scope[key];
          const offerValue = offer[key as keyof Offer] as number;

          if (!offerValue) {
            // eslint-disable-next-line no-continue
            continue;
          }

          // Works like OR operation
          if (selectedFilters.includes(offerValue)) {
            result.push(1);
          } else {
            result.push(0);
          }
        }

        if (!result.length) {
          return false;
        }

        // All filters must be true
        // Works like AND operation
        return result.reduce((accu, value) => accu * value);
      });
    }

    return [];
  }

  public static paginate(
    offersSource: Offer[],
    currentPage: number,
    itemsPerPage: number
  ): Offer[] {
    if (!offersSource.length) {
      return [];
    }

    if (offersSource.length <= itemsPerPage) {
      return offersSource;
    }

    const start = currentPage * itemsPerPage - itemsPerPage;
    const end = start + itemsPerPage;
    return offersSource?.slice(start, end);
  }

  public static async changeOfferState(
    subject: Offer,
    newState: number | OfferStates,
    commit: Commit
  ): Promise<boolean> {
    const proxyOffer: Offer = {
      uuid: subject.uuid,
      state: newState,
      expirationDate: subject.expirationDate
    };

    try {
      await OfferService.update(proxyOffer);

      commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, 200);
      commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, undefined);

      return true;
    } catch (error: unknown) {
      const parsedError = ErrorHelper.getAxiosError(error);

      if (parsedError) {
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, parsedError.status);
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, parsedError.data);
      }

      return false;
    }
  }

  public static async fetchAction(
    lastAction: FetchAction,
    action: FetchAction,
    dispatch: Dispatch,
    commit: Commit,
    state: OffersState,
    types: number[] | null = null,
    force: boolean = false
  ): Promise<void> {
    if (state.lastAction !== action || force) {
      commit(MutationTypes.SET_IS_FETCHING, true);

      await dispatch(ActionTypes.CLEAR_OFFERS);

      try {
        // eslint-disable-next-line default-case
        switch (action) {
          case 'ALL_OFFERS':
            commit(MutationTypes.SET_OFFERS, await OfferService.index());
            break;

          case 'PUBLIC_OFFERS':
            commit(MutationTypes.SET_OFFERS, await OfferService.published(types));
            break;

          case 'CONCLUDED_OFFERS':
            commit(MutationTypes.SET_OFFERS, await OfferService.concluded());
            break;

          case 'DELETED_OFFERS':
            commit(MutationTypes.SET_OFFERS, await OfferService.deleted());
            break;
        }

        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, 200);
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, undefined);
      } catch (error: unknown) {
        const parsedError = ErrorHelper.getAxiosError(error);

        if (parsedError) {
          commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, parsedError.status);
          commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, parsedError.data);
        }
      }

      commit(MutationTypes.SET_LAST_ACTION, action);
      commit(MutationTypes.SET_IS_FETCHING, false);
    }

    await dispatch(ActionTypes.CHANGE_PAGE, 1);
  }
}
