export enum RoutesNames {
  HOME_ADMINISTRATION = 'AdminHome',
  OFFERS_ADMINISTRATION = 'AdminAdministration',
  COMPETITIONS_RESULTS = 'AdminCompetitionsResults',
  USERS_AND_PERMS_ADMINISTRATION = 'AdminUsersAndPermissions',
  SUPER_ADMIN_MENU = 'SuperAdminMenu'
}

export enum RoutesPaths {
  HOME_ADMINISTRATION = '',
  OFFERS_ADMINISTRATION = 'offers',
  COMPETITIONS_RESULTS = 'competitions-results',
  USERS_AND_PERMS_ADMINISTRATION = 'users',
  SUPER_ADMIN_MENU = 'super-admin-menu'
}
