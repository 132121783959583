import api from '@/modules/core/core-api';
import Offer from '@/models/Offer';
import { FilesQueue } from '@/components/Pwr/PwrFiles/lang/types';

export default class OffersService {
  private static groupRoot = 'offers';

  // Gets all offers or filtered by key
  public static async index(filterKey: string | null = null): Promise<Offer[]> {
    const url = this.groupRoot + (filterKey ? `?key=${filterKey}` : ``);
    const response = await api.get(url);
    const { data }: { data: Offer[] } = response;
    return data;
  }

  // Gets all published and active offers
  public static async published(types: number[] | null = null): Promise<Offer[]> {
    const response = await api.get(`${this.groupRoot}/published`, { params: { types } });
    const { data }: { data: Offer[] } = response;
    return data;
  }

  // Gets all concluded and active offers
  public static async concluded(): Promise<Offer[]> {
    const response = await api.get(`${this.groupRoot}/concluded`);
    const { data }: { data: Offer[] } = response;
    return data;
  }

  // Gets all deleted offers
  public static async deleted(): Promise<Offer[]> {
    const response = await api.get(`${this.groupRoot}/deleted`);
    const { data }: { data: Offer[] } = response;
    return data;
  }

  // Gets specific offer
  public static async get(offerUuid: string): Promise<Offer> {
    const response = await api.get(`${this.groupRoot}/${offerUuid}`);
    const { data }: { data: Offer } = response;
    return data;
  }

  // Create new offer
  public static async store(offer: Offer): Promise<Offer> {
    const response = await api.post(this.groupRoot, offer);
    const { data }: { data: Offer } = response;
    return data;
  }

  // Create new offer from template
  public static async storeFromTemplate(
    referenceNumber: string,
    templateOfferUuid: string
  ): Promise<Offer> {
    const response = await api.post(`${this.groupRoot}/template/${templateOfferUuid}`, {
      referenceNumber
    });
    const { data }: { data: Offer } = response;
    return data;
  }

  // Update offer
  public static async update(offer: Offer): Promise<Offer> {
    const response = await api.patch(`${this.groupRoot}/${offer.uuid}`, offer);
    const { data }: { data: Offer } = response;
    return data;
  }

  // Delete offer
  public static async delete(offer: Offer): Promise<boolean> {
    const response = await api.delete(`${this.groupRoot}/${offer.uuid}`);
    return response.status === 200;
  }

  // Schedule offer
  public static async schedule(offer: Offer, publicationDate: string): Promise<Offer> {
    const response = await api.patch(`${this.groupRoot}/${offer.uuid}/schedule`, {
      publicationDate
    });
    const { data }: { data: Offer } = response;
    return data;
  }

  // Restore offer
  public static async restore(offer: Offer): Promise<Offer> {
    const response = await api.post(`${this.groupRoot}/restore`, { 'uuid': offer.uuid });
    const { data }: { data: Offer } = response;
    return data;
  }

  /**
   * Attach filepond uploads to offer
   * @param offer Subject
   * @param files FilesQueue
   */
  public static async attach(offer: Offer, files: FilesQueue): Promise<Offer> {
    const response = await api.post(`${this.groupRoot}/${offer.uuid}/attach`, {
      'serverIds': files.serverIds,
      'destination': files.destination
    });
    const { data }: { data: Offer } = response;
    return data;
  }
}
