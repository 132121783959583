
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component({
  metaInfo: {
    title: 'Oferty pracy'
  }
})
export default class App extends PwrVue {}
