import { UserBaseModel } from '@/modules/core/core-types';

export interface AuthState {
  user: UserBaseModel | null;
}

export enum MutationTypes {
  SET_LOGGED_USER = 'SET_LOGGED_USER',
}

export enum ActionTypes {
  CHANGE_USER = 'CHANGE_USER',
}

export enum GetterTypes {
  GET_LOGGED_USER = 'GET_LOGGED_USER',
}
