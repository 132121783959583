export interface RootState {
  enableScrollUpFab: boolean;
}

export enum MutationTypes {
  SET_ENABLE_SCROLL_UP_FAB = 'SET_ENABLE_SCROLL_UP_FAB'
}

export enum ActionTypes {
  DISABLE_SCROLL_UP_FAB = 'DISABLE_SCROLL_UP_FAB',
  ENABLE_SCROLL_UP_FAB = 'ENABLE_SCROLL_UP_FAB'
}

export enum GetterTypes {
  GET_ENABLE_SCROLL_UP_FAB = 'GET_ENABLE_SCROLL_UP_FAB'
}
