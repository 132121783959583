import VueRouter, { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { GetterTypes as RouterGetterTypes } from '@/store/router/types';
import RouterHelperProvider from '@/helpers/routes/RouterHelperProvider';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';
import { RoutesNames as OfferRoutesNames } from '@/router/offers/types';

const routerNamespace = 'router';

export default class RouterHelper {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private router: VueRouter,
    private store: Store<any> // eslint-disable-next-line no-empty-function
  ) {}

  public async goToLastValidRoute(router: VueRouter): Promise<Route | void> {
    // History does not contain routes that exist in routesNamesToAvoidGoingBackTo
    const history: Route[] =
      this.store.getters[`${routerNamespace}/${RouterGetterTypes.GET_HISTORY}`];

    if (history.length === 1 && history[0].fullPath === '/') {
      return this.goToLastSessionRoute();
    }

    const filteredHistory = history.filter(
      (history: Route) => history.name !== router.currentRoute.name
    );

    return router.push({
      name: filteredHistory.length
        ? filteredHistory[filteredHistory.length - 1].name || RouterHelperProvider.fallbackRouteName
        : RouterHelperProvider.fallbackRouteName
    });
  }

  public async goToLastSessionRoute(): Promise<Route | void> {
    const lastSessionRouteName =
      this.store.getters[`${routerNamespace}/${RouterGetterTypes.GET_LAST_SESSION_ROUTE_NAME}`];
    return this.router.push({ name: lastSessionRouteName });
  }

  public async go(
    routeName: GlobalRoutesNames | SiteRoutesNames | AdminRoutesNames | OfferRoutesNames | string,
    params?: Dictionary<string>,
    query?: Dictionary<string | (string | null)[]>
  ): Promise<void> {
    await this.router.push({ name: routeName, params, query });
  }
}
