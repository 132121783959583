import { AxiosResponse } from 'axios';
import { Dictionary } from 'vue-router/types/router';
import { TokensModel, UserBaseModel, UserCredentialsModel } from '@/modules/core/core-types';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import TokenUserResponse from '../types/TokenUserResponse';
import TokenService from './TokenService';
import CoreAuthProvider from '../CoreAuthProvider';
import { ActionTypes, GetterTypes } from '../store/auth/types';
import PermissionsParser from '../Helpers/PermissionsParser';

export type LoginListenerCallback = { (user: UserBaseModel | null): any };
export type LogoutListenerCallback = { (): any };

export default class AuthService {
  private static groupRoot = 'auth';
  private static loginListeners: LoginListenerCallback[] = [];
  private static logoutListeners: LogoutListenerCallback[] = [];

  public static onLogin(callback: LoginListenerCallback) {
    this.loginListeners.push(callback);
  }

  public static onLogout(callback: LogoutListenerCallback) {
    this.logoutListeners.push(callback);
  }

  public static notifyLoginListeners(user: UserBaseModel | null): void {
    this.loginListeners.forEach((callback: LoginListenerCallback) => {
      callback(user);
    });
  }

  public static notifyLogoutListeners(): void {
    this.logoutListeners.forEach((callback: LogoutListenerCallback) => {
      callback();
    });
  }

  /**
   * @deprecated Use keycloak plugin instead (vue-core)
   * @param successEndpoint
   * @param errorEndpoint
   */
  public static ssoLogin(successEndpoint: string, errorEndpoint: string | null = null): void {
    LoggingHelper.log('SSO login', LogLevel.DEBUG, ['core-auth', 'AuthService', 'ssoLogin']);
    // eslint-disable-next-line no-restricted-globals
    location.replace(
      `${CoreAuthProvider.apiUrl}/${this.groupRoot}/sso/?success=${successEndpoint}${
        errorEndpoint ? '&error=' : ''
      }${errorEndpoint}`
    );
  }

  /**
   * @deprecated Use keycloak plugin instead (vue-core)
   * @param routeQuery
   */
  public static wasSsoLogin(routeQuery: Dictionary<string | (string | null)[]>): boolean {
    LoggingHelper.log(
      `Access token: ${routeQuery.accessToken}, Refresh token: ${routeQuery.refreshToken}`,
      LogLevel.DEBUG,
      ['core-auth', 'AuthService', 'wasSsoLogin']
    );

    return !!routeQuery.accessToken && !!routeQuery.refreshToken;
  }

  /**
   * @deprecated Use keycloak plugin instead (vue-core)
   * @param routeQuery
   */
  public static async afterSsoLogin(routeQuery: Dictionary<string | (string | null)[]>): Promise<UserBaseModel | null> {
    if (this.wasSsoLogin(routeQuery)) {
      LoggingHelper.log('After SSO login', LogLevel.DEBUG, ['core-auth', 'AuthService', 'afterSsoLogin']);
      LoggingHelper.log('Reading tokens from route', LogLevel.DEBUG, ['core-auth', 'AuthService', 'afterSsoLogin']);
      const accessToken: string = routeQuery.accessToken.toString();
      const refreshToken: string = routeQuery.refreshToken.toString();

      if (accessToken !== '' && refreshToken !== '') {
        LoggingHelper.log('Tokens found.', LogLevel.DEBUG, ['core-auth', 'AuthService', 'afterSsoLogin']);
        TokenService.setSsoLogin();
        TokenService.saveTokensToLocalStorage({
          accessToken,
          refreshToken
        });

        const user = await this.me();
        this.notifyLoginListeners(user);

        return user;
      }

      LoggingHelper.log('No tokens found!', LogLevel.DEBUG, ['core-auth', 'AuthService', 'afterSsoLogin']);
    }

    this.notifyLoginListeners(null);
    return null;
  }

  public static async me(): Promise<UserBaseModel> {
    LoggingHelper.log('Fetching user data.', LogLevel.DEBUG, ['core-auth', 'AuthService', 'me']);
    await this.cleatStoreUser();

    const response = await CoreAuthProvider.api.get(this.groupRoot);
    const { data }: { data: UserBaseModel } = response.data;

    if (data.permissions) {
      const permParser = new PermissionsParser(data.permissions);
      data.permissionsParsed = permParser.parse();
    }

    await this.changeUserInStore(data);
    this.notifyLoginListeners(data);

    LoggingHelper.log('Fetched user data.', LogLevel.DEBUG, ['core-auth', 'AuthService', 'me']);

    return data;
  }

  public static async login(credentials: UserCredentialsModel): Promise<TokenUserResponse> {
    LoggingHelper.log('Logging in', LogLevel.INFO, ['core-auth', 'AuthService', 'login']);
    await this.cleatStoreUser();

    const response = await CoreAuthProvider.api.post(`${this.groupRoot}/login`, credentials);
    const tokenAndUser = this.parseTokensAndUserFromResponse(response);
    TokenService.saveTokensToLocalStorage(tokenAndUser.tokens);

    await this.changeUserInStore(tokenAndUser.user);

    LoggingHelper.log('Login succeed!', LogLevel.DEBUG, ['core-auth', 'AuthService', 'login']);

    this.notifyLoginListeners(tokenAndUser.user);

    return tokenAndUser;
  }

  private static parseTokensAndUserFromResponse(response: AxiosResponse): TokenUserResponse {
    LoggingHelper.log('Parsing tokens from response', LogLevel.DEBUG, [
      'core-auth',
      'AuthService',
      'parseTokensAndUserFromResponse'
    ]);
    const { data }: { data: TokensModel } = response.data;
    const { user }: { user: UserBaseModel } = response.data;

    if (user.permissions) {
      const permParser = new PermissionsParser(user.permissions);
      user.permissionsParsed = permParser.parse();
    }

    return { tokens: data, user };
  }

  public static async refresh(): Promise<TokenUserResponse | null> {
    LoggingHelper.log('Refreshing tokens', LogLevel.INFO, ['core-auth', 'AuthService', 'refresh']);

    await this.cleatStoreUser();

    if (TokenService.wasSsoLogin()) {
      if (CoreAuthProvider.keycloakServiceInstance) {
        await CoreAuthProvider.keycloakServiceInstance.login();
      }

      return null;
    }

    const tokens: TokensModel | null = TokenService.getTokensFromLocalStorage();

    if (tokens) {
      const response = await CoreAuthProvider.api.post(`${this.groupRoot}/refresh`, tokens);
      const tokenAndUser = this.parseTokensAndUserFromResponse(response);
      TokenService.saveTokensToLocalStorage(tokenAndUser.tokens);

      await this.changeUserInStore(tokenAndUser.user);

      LoggingHelper.log('Refresh succeed!', LogLevel.DEBUG, ['core-auth', 'AuthService', 'refresh']);

      this.notifyLoginListeners(tokenAndUser.user);
      return tokenAndUser;
    }

    LoggingHelper.log('Refresh failed!', LogLevel.ERROR, ['core-auth', 'AuthService', 'refresh']);

    this.notifyLoginListeners(null);
    return null;
  }

  public static async logout(): Promise<boolean> {
    LoggingHelper.log('Logging out', LogLevel.INFO, ['core-auth', 'AuthService', 'logout']);

    await this.cleatStoreUser();

    const tokens: TokensModel | null = TokenService.getTokensFromLocalStorage();
    const wasSsoLogin: boolean = TokenService.wasSsoLogin();

    if (wasSsoLogin) {
      LoggingHelper.log('Closing sso session', LogLevel.INFO, ['core-auth', 'AuthService', 'logout']);

      TokenService.clearSsoLogin();

      try {
        const keycloakLogoutUrl = await this.logoutSso();
        TokenService.removeTokensFromLocalStorage();
        // eslint-disable-next-line no-restricted-globals
        location.replace(keycloakLogoutUrl);
      } catch (e: unknown) {
        LoggingHelper.log(e, LogLevel.ERROR, ['core-auth', 'AuthService', 'logout']);
        return false;
      }
    } else if (tokens) {
        LoggingHelper.log('Invalidating tokens', LogLevel.DEBUG, ['core-auth', 'AuthService', 'logout']);

        try {
          await CoreAuthProvider.api.get(`${this.groupRoot}/logout`);
          TokenService.removeTokensFromLocalStorage();
        } catch (e: unknown) {
          LoggingHelper.log(e, LogLevel.ERROR, ['core-auth', 'AuthService', 'logout']);
          return false;
        }

        LoggingHelper.log('Deleting tokens from local storage', LogLevel.DEBUG, ['core-auth', 'AuthService', 'logout']);
      }

    LoggingHelper.log('Logout done!', LogLevel.DEBUG, ['core-auth', 'AuthService', 'logout']);

    this.notifyLogoutListeners();

    return true;
  }

  public static async getUserFromStore(): Promise<UserBaseModel | null> {
    if (!CoreAuthProvider.store) {
      LoggingHelper.log('Store is not used!', LogLevel.DEBUG, ['core-auth', 'AuthService', 'getUserFromStore']);
      return null;
    }

    LoggingHelper.log('Getting user from store.', LogLevel.DEBUG, ['core-auth', 'AuthService', 'getUserFromStore']);

    // eslint-disable-next-line no-return-await
    return await CoreAuthProvider.store.getters[`auth/${GetterTypes.GET_LOGGED_USER}`];
  }

  private static async logoutSso(): Promise<string> {
    const response = await CoreAuthProvider.api.get(`${this.groupRoot}/sso/logout`);
    return response.data.message;
  }

  public static async cleatStoreUser(): Promise<void> {
    await this.changeUserInStore(null);
  }

  public static async changeUserInStore(user: UserBaseModel | null): Promise<void> {
    const {store} = CoreAuthProvider;

    if (store) {
      await store.dispatch(`${this.groupRoot}/${ActionTypes.CHANGE_USER}`, user);
    }
  }
}
