import { RouteConfig } from 'vue-router';
import RouterModuleInterface from '@/router/RouterModuleInterface';
import { RoutesNames, RoutesPaths } from '@/router/site/types';

const OpLayout = () => import('@/views/Layouts/OpLayout.vue');
const CompetitionsResultsView = () =>
  import('@/views/CompetitionsResults/CompetitionsResultsView.vue');
const ContactView = () => import('@/views/Contact/ContactView.vue');
const EmptyLayout = () => import('@/views/Layouts/EmptyLayout.vue');
const OffersForMiscView = () => import('@/views/Offers/OffersForMiscView.vue');
const OffersForTeachersView = () => import('@/views/Offers/OffersForTeachersView.vue');

export default class SiteRoutes implements RouterModuleInterface {
  module(): RouteConfig {
    return {
      path: '/',
      component: OpLayout,
      children: [
        {
          path: RoutesPaths.HOME,
          name: RoutesNames.HOME,
          component: EmptyLayout,
          beforeEnter: (to, from, next) => {
            // This is a default redirect to OFFERS_FOR_TEACHERS view
            // In the future this can be deleted and Home view can be inserted as component
            next({ 'name': RoutesNames.OFFERS_FOR_TEACHERS });
          }
        },
        {
          path: RoutesPaths.OFFERS_FOR_TEACHERS,
          name: RoutesNames.OFFERS_FOR_TEACHERS,
          component: OffersForTeachersView
        },
        {
          path: RoutesPaths.OFFERS_FOR_NOT_TEACHERS_OFFERS,
          name: RoutesNames.OFFERS_FOR_NOT_TEACHERS_OFFERS,
          component: OffersForMiscView
        },
        {
          path: RoutesPaths.COMPETITIONS_RESULTS,
          name: RoutesNames.COMPETITIONS_RESULTS,
          component: CompetitionsResultsView
        },
        {
          path: RoutesPaths.CONTACT,
          name: RoutesNames.CONTACT,
          component: ContactView
        },
        {
          path: RoutesPaths.CAREER,
          name: RoutesNames.CAREER,
          component: ContactView
        }
      ]
    };
  }
}
