import { Route } from 'vue-router';

export interface PushActionPayload {
  from: Route;
  to: Route;
}

export interface RouterState {
  lastRoute?: Route;
  history: Route[];
  lastSessionRouteName?: string;
}

export enum GetterTypes {
  GET_LAST_ROUTE = 'GET_LAST_ROUTE',
  GET_HISTORY = 'GET_HISTORY',
  GET_LAST_SESSION_ROUTE_NAME = 'GET_LAST_SESSION_ROUTE_NAME'
}

export enum ActionTypes {
  PUSH = 'PUSH'
}

export enum MutationTypes {
  SET_LAST_ROUTE = 'SET_LAST_ROUTE',
  SET_HISTORY = 'SET_HISTORY',
  SET_LAST_SESSION_ROUTE_NAME = 'SET_LAST_SESSION_ROUTE_NAME'
}
