import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import { RoutesNames as OffersRoutesNames } from '@/router/offers/types';

export default class RouterHelperProvider {
  /**
   * Routes that router should not go back to
   * For example afer login do no go back to site under construction vue,
   * as it creates infinite loop.
   */
  public static routesNamesToAvoidGoingBackTo: string[] = [
    GlobalRoutesNames.LOGIN,
    GlobalRoutesNames.LOGOUT,
    GlobalRoutesNames.SITE_UNDER_CONSTRUCTION,
    GlobalRoutesNames.SITE_UNDER_MAINTENANCE,
    GlobalRoutesNames.SESSION_TIMEOUT,
    GlobalRoutesNames.E401,
    GlobalRoutesNames.E404,
    OffersRoutesNames.VIEW,
    OffersRoutesNames.EDIT,
    OffersRoutesNames.NEW
  ];

  public static fallbackRouteName: string = SiteRoutesNames.OFFERS_FOR_TEACHERS;
}
