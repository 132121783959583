import Vue from 'vue';
import VueMeta from 'vue-meta';
import moment from 'moment';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome';
import { CoreLoginPanelProvider } from 'core-login-panel';
import { AuthService, CoreAuthProvider, PermissionsHelper, TokenService } from '@/modules/core/core-auth';
import api, { CoreApiProvider } from '@/modules/core/core-api';
import { CoreInputValidatorsProvider } from '@/modules/core/core-input-validators';
import { ProcessEnvBaseModel, UserBaseModel } from '@/modules/core/core-types';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import {
  CoreDynamicDictionariesProvider,
  DictGetterTypes,
  DictionariesService
} from '@/modules/core/core-dynamic-dictionaries';
import SessionWatcherProvider from '@/modules/SessionWatcher/SessionWatcherProvider';
import SessionWatcherService from '@/modules/SessionWatcher/SessionWatcherService';
import LangSelectorProvider from '@/modules/LangSelector/LangSelectorProvider';
import maintenanceInterceptor from '@/modules/Maintenance/interceptors/MaintenanceInterceptor';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'moment/locale/pl';
import i18n from './plugins/i18n';
import './plugins/filters'

function checkLogging(user: UserBaseModel | null) {
  /**
   * Core Helpers
   */

  if (localStorage.getItem('LOGS_ENABLE') && user) {
    PermissionsHelper.hasRole('super_administrator', user).then((result: boolean) => {
      if (result) {
        LoggingHelper.logLevel = Number(localStorage.getItem('LOGS_LEVEL')) ?? LogLevel.DEBUG;
      }
    });
  } else {
    LoggingHelper.logLevel = 10000;
  }
}

AuthService.onLogin(checkLogging);

// First check if maintenance mode
maintenanceInterceptor(api, router, [GlobalRoutesNames.LOGOUT, GlobalRoutesNames.LOGIN]);

// app_url./ => app_url/
const env = process.env as ProcessEnvBaseModel;
const appUrl = env.BASE_URL;

if (window.location.href.includes(`${appUrl}./`)) {
  const url = window.location.href.replace(`${appUrl}./`, `${appUrl}/`);
  window.location.replace(url);
}

moment.locale('pl');
/**
 * Core Api
 */
CoreApiProvider.setApiUrl((process.env as ProcessEnvBaseModel).VUE_APP_API_URL);

/**
 * Core LoginView Panel
 */
// Creates side effects in i18n
CoreLoginPanelProvider.mergeLocales(i18n);

/**
 * Core Input Validators
 */
CoreInputValidatorsProvider.mergeLocales(i18n);

/**
 * Core Auth
 */
CoreAuthProvider.attachInterceptors(
  api,
  {
    unauthorizedRouteName: GlobalRoutesNames.E401,
    ssoPostAuthRouteName: GlobalRoutesNames.LOGIN
  },
  router
);
CoreAuthProvider.attachStore(store);
CoreAuthProvider.apiUrl = (process.env as ProcessEnvBaseModel).VUE_APP_API_URL;

if (TokenService.isAccessTokenValid()) {
  AuthService.me();
}

/**
 * Core Dynamic Dictionaries
 */
CoreDynamicDictionariesProvider.api = api;
CoreDynamicDictionariesProvider.store = store;
CoreDynamicDictionariesProvider.modules = [
  { key: 'offer', apiEndpoint: 'offers' },
  { key: 'user', apiEndpoint: 'users' }
];

/**
 * Session Watcher
 */
SessionWatcherProvider.mergeLocales(i18n);
SessionWatcherProvider.attachInterceptors(api);
SessionWatcherProvider.attachRouter(router);
SessionWatcherProvider.redirectRouteName = GlobalRoutesNames.SESSION_TIMEOUT;

if (process.env.NODE_ENV !== 'development') {
  SessionWatcherService.startCounter();
}

/**
 * Lang Selector
 */
LangSelectorProvider.attachInterceptors(api);
LangSelectorProvider.i18n = i18n;
LangSelectorProvider.vuetify = vuetify;
LangSelectorProvider.store = store;

/** Icons
 * @see(https://www.npmjs.com/package/@fortawesome/vue-fontawesome)
 */
Vue.component('font-awesome-icon', FontAwesomeIcon); // Icon component
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.config.productionTip = false;

Vue.use(VueMeta, { refreshOnceOnNavigation: true });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');

const currentDictionaries =
  store.getters[`${DictionariesService.dictNamespace}/${DictGetterTypes.GET_DICTIONARIES}`];

if (!Object.keys(currentDictionaries).length) {
  DictionariesService.fetchDictionaries();
}
