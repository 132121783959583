import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import { ActionTypes, GetterTypes, MutationTypes, SnackbarPayload, SnackbarState } from './types';

const namespaced: boolean = true;

// @ts-ignore
const state: SnackbarState = {
  isOpen: false,
  message: '',
  type: PwrSnackbarTypes.DEFAULT,
  timeout: 5000
};

const mutations: MutationTree<SnackbarState> = {
  [MutationTypes.SET_IS_OPEN](state, payload: boolean): void {
    state.isOpen = payload;
  },
  [MutationTypes.SET_MESSAGE](state, payload: string): void {
    state.message = payload;
  },
  [MutationTypes.SET_TYPE](state, payload: PwrSnackbarTypes): void {
    state.type = payload;
  },
  [MutationTypes.SET_TIMEOUT](state, payload: number): void {
    state.timeout = payload;
  }
};

const actions: ActionTree<SnackbarState, any> = {
  [ActionTypes.SHOW_SNACKBAR]({ commit }, payload: SnackbarPayload): void {
    commit(MutationTypes.SET_IS_OPEN, true);
    commit(MutationTypes.SET_MESSAGE, payload.message);
    commit(MutationTypes.SET_TYPE, payload.type ?? PwrSnackbarTypes.DEFAULT);
    commit(MutationTypes.SET_TIMEOUT, payload.timeout ?? 5000);
  }
};

const getters: GetterTree<SnackbarState, any> = {
  [GetterTypes.GET_IS_OPEN](state): boolean {
    return state.isOpen;
  },
  [GetterTypes.GET_MESSAGE](state): string {
    return state.message;
  },
  [GetterTypes.GET_TYPE](state): string {
    return state.type;
  },
  [GetterTypes.GET_TIMEOUT](state): number {
    return state.timeout;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
