const en = {
  coreInputValidator: {
    minRuleString:
      '{label} must be at least one character! | {label} must be at least {n} characters long!',
    maxRuleString:
      '{label} can not be longer than one character! | {label} can not be longer than {n} characters!',
    minRuleNumber: '{label} must be at least {minLength}!',
    maxRuleNumber: '{label} can not be bigger than {maxLength}!',
    isNumberRule: '{label} must be numeric!',
    required: '{label} is required!',
    input: 'Input',
    date: 'Date',
    minDate: '{label} must be after {date}!',
    maxDate: '{label} must be before {date}!'
  }
};

export default en;
