import { AxiosInstance } from 'axios';
import { IVueI18n } from 'vue-i18n';
import Vuetify from 'vuetify';
import { Store } from 'vuex';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import { ActionTypes } from '@/modules/LangSelector/store/types';
import langLoaderInterceptor from './LangSelectorInterceptor';
import LangSelectorService from './LangSelectorService';
import lang from './store/lang';

export default class LangSelectorProvider {
  public static attachInterceptors(api: AxiosInstance): void {
    LoggingHelper.log('Attaching lang interceptor', LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorProvider',
      'attachInterceptors'
    ]);

    langLoaderInterceptor(api);
  }

  static set i18n(i18n: IVueI18n) {
    LoggingHelper.log('Setting i18n', LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorProvider',
      'i18n setter'
    ]);

    LangSelectorService.i18n = i18n;
  }

  static set vuetify(vuetify: Vuetify) {
    LoggingHelper.log('Setting vuetify', LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorProvider',
      'vuetify setter'
    ]);

    LangSelectorService.vuetify = vuetify;
  }

  static set store(store: Store<any>) {
    LoggingHelper.log('Setting store and registering lang module.', LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorProvider',
      'store setter'
    ]);

    LangSelectorService.store = store;
    store.registerModule(['lang'], lang);
    store.dispatch(`lang/${ActionTypes.SELECT_LANG}`, LangSelectorService.lang);
  }
}
