// Dictionaries

export interface DictionaryKey {
  key: string;
  value: number;
  label: string;
}

export interface RawDictionary {
  name: string;
  labels: DictionaryKey[];
}

export interface ParsedDictionary {
  name: string;
  labels: { [key: string]: string };
  constants: { [key: string]: string };
}

export interface DictionaryOfDictionaries {
  [key: string]: ParsedDictionary;
}

export interface DictionaryOfRawDictionaries {
  [key: string]: RawDictionary;
}

export interface DictionaryOfModules {
  [key: string]: DictionaryOfDictionaries;
}

// Vuex

export interface DictionariesState {
  dictionaries: DictionaryOfModules;
}

export enum MutationTypes {
  SET_DICTIONARIES = 'SET_DICTIONARIES'
}

export enum ActionTypes {
  FETCH_DICTIONARIES = 'FETCH_DICTIONARIES',
  CLEAR_DICTIONARIES = 'CLEAR_DICTIONARIES'
}

export enum GetterTypes {
  GET_DICTIONARIES = 'GET_DICTIONARIES'
}

// Provider
export interface Module {
  key: string;
  apiEndpoint: string;
}

export interface Hook {
  key: string;
  callback: () => Promise<DictionaryOfRawDictionaries>;
}
