import { debounce } from 'lodash';
import VueRouter from 'vue-router';
import { IVueI18n } from 'vue-i18n';
import { AxiosInstance } from 'axios';
import { AuthService, TokenService } from '@/modules/core/core-auth';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';

export default class SessionWatcherService {
  public static afkTime = 900 * 1000; // in ms - 15 min
  public static router: VueRouter;
  public static i18n: IVueI18n;
  public static api: AxiosInstance;
  private static counterHandle: number | null = null;
  public static redirectRouteName = 'Login';

  private static afkCounterResetDebounce = debounce(SessionWatcherService.refreshCounter, 1000);

  public static startCounter(): void {
    LoggingHelper.log('Starting counter.', LogLevel.INFO, [
      'modules',
      'SessionWatcherService',
      'startCounter'
    ]);

    this.counterHandle = setTimeout(this.timeoutHandler, this.afkTime);

    LoggingHelper.log('Starting counter.', LogLevel.DEBUG, [
      'Registering refresh events',
      'SessionWatcherService',
      'startCounter'
    ]);

    window.addEventListener('mousemove', () => {
      SessionWatcherService.afkCounterResetDebounce();
    });

    window.addEventListener('keypress', () => {
      SessionWatcherService.afkCounterResetDebounce();
    });
  }

  public static refreshCounter(): void {
    LoggingHelper.log('Refreshing counter.', LogLevel.DEBUG, [
      'modules',
      'SessionWatcherService',
      'refreshCounter'
    ]);

    if (this.counterHandle) {
      clearTimeout(this.counterHandle);
      this.startCounter();
    }
  }

  public static async timeoutHandler(): Promise<void> {
    if (
      SessionWatcherService.router &&
      SessionWatcherService.router.currentRoute.name !== SessionWatcherService.redirectRouteName
    ) {
      if (!TokenService.isAccessTokenValid()) {
        LoggingHelper.log('User not logged int, continuing ...', LogLevel.INFO, [
          'modules',
          'SessionWatcherService',
          'timeoutHandler'
        ]);

        return;
      }

      LoggingHelper.log('Logging out', LogLevel.INFO, [
        'modules',
        'SessionWatcherService',
        'timeoutHandler'
      ]);

      await AuthService.logout();

      await SessionWatcherService.router.push({
        name: SessionWatcherService.redirectRouteName,
        params: {
          sessionWatcher: SessionWatcherService.i18n.t('modules.sessionWatcher.reason') as string
        }
      });
    }
  }
}
