import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { UserBaseModel } from '@/modules/core/core-types';
import { GetterTypes, MutationTypes, AuthState, ActionTypes } from './types';

const namespaced: boolean = true;

const state: AuthState = {
  user: null
};

const getters: GetterTree<AuthState, any> = {
  [GetterTypes.GET_LOGGED_USER](state): UserBaseModel | null {
    if (state) {
      return state.user;
    }

    return null;
  }
};

const actions: ActionTree<AuthState, any> = {
  [ActionTypes.CHANGE_USER]({ commit }, user: UserBaseModel): void {
    commit(MutationTypes.SET_LOGGED_USER, user);
  }
};

const mutations: MutationTree<AuthState> = {
  [MutationTypes.SET_LOGGED_USER](state, user: UserBaseModel | null): void {
    state.user = user;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
