export enum RoutesNames {
  NEW = 'offer.new',
  VIEW = 'offer.view',
  EDIT = 'offer.edit'
}

export enum RoutesPaths {
  NEW = 'new',
  VIEW = 'view',
  EDIT = 'edit'
}
