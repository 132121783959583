export enum RoutesNames {
  HOME = 'Home',
  OFFERS_FOR_TEACHERS = 'OffersForTeachers',
  OFFERS_FOR_NOT_TEACHERS_OFFERS = 'MiscOffers',
  COMPETITIONS_RESULTS = 'CompetitionsResults',
  CONTACT = 'Contact',
  CAREER = 'Career'
}

export enum RoutesPaths {
  HOME = '',
  OFFERS_FOR_TEACHERS = 'for-teachers',
  OFFERS_FOR_NOT_TEACHERS_OFFERS = 'for-non-teachers',
  COMPETITIONS_RESULTS = 'competitions-results',
  CONTACT = 'contact',
  CAREER = 'career'
}
