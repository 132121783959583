const pl = {
  appName: 'Oferty Pracy',
  views: {
    adminPanel: {
      jobOffers: {
        form: {
          header: 'Podstawowe informacje'
        },
        title: 'Administracja Ofert Pracy',
        dataTable: {
          headers: {
            operations: 'Operacje'
          }
        },
        wyswig: {
          buttons: {
            default: 'Wartość domyślna'
          }
        },
        snackbar: {
          stateValidation: {
            message:
              'Niektóre elementy oferty nie spełniają wymagań dla tej akcji! Kliknij "Wprowadź poprawki" aby zobaczyć więcej.',
            message2: 'Niektóre elementy oferty nie spełniają wymagań dla tej akcji!',
            buttons: {
              close: 'Zamknij',
              edit: 'Wprowadź poprawki'
            }
          },
          messages: {
            success: {
              published: 'Oferta została opublikowana!',
              scheduledPublication: 'Publikacja oferty została zaplanowana!',
              deleted: 'Oferta została usunięta!',
              archived: 'Oferta została zarchiwizowana!'
            },
            errors: {
              canNotBeArchive: 'Ta oferta nie może zostać zarchiwizowana',
              notFound: 'Nie znaleziono oferty!'
            }
          }
        },
        components: {
          newOfferDialog: {
            title: 'Nowa oferta pracy',
            titleFromTemplate: 'Nowa oferta pracy ze wzorca'
          },
          offerScheduleDialog: {
            title: 'Wybierz datę publikacji',
            buttons: {
              accept: 'Zaplanuj'
            }
          },
          yesNoDialog: {
            cannotUndone: 'Tej akcji nie da się cofnąć!',
            actions: {
              publish: {
                title: 'Potwierdzenie publikacji',
                message: 'Czy na pewno chcesz opublikować ofertę?'
              },
              delete: {
                title: 'Potwierdzenie usunięcia',
                message: 'Czy na pewno chcesz usunąć ofertę?'
              },
              archive: {
                title: 'Potwierdzenie archiwizacji',
                message: 'Czy na pewno chcesz zarchiwizować ofertę?'
              }
            }
          },
          competitionConclusionDialog: {
            title: 'Rozstrzygnięcie konkursu',
            buttons: {
              yes: 'Rozstrzygnij',
              yes2: 'Aktualizuj'
            },
            errors: {
              offerRef: 'Nie można rozstrzygnąć oferty, bo brakuje do niej odniesienia!',
              offer: 'Nie udało się rozstrzygnąć oferty!',
              fileAttach: 'Nie udało się dodać pliku do oferty!',
              fileUpload: 'Nie udało się poprawnie odebrać pliku!'
            },
            success: {
              offer: 'Rozstrzygnięto ofertę!',
              offer2: 'Zaktualizowano rozstrzygnięcie oferty!'
            }
          },
          actionItemsGroup: {
            tooltips: {
              publish: 'Opublikuj',
              scheduledPublication: 'Zaplanuj publikację',
              archive: 'Zarchiwizuj',
              conclude: 'Rozstrzygnij',
              concludeAgain: 'Rozstrzygnij ponownie',
              edit: 'Edytuj',
              template: 'Użyj jako wzorca',
              preview: 'Podgląd',
              delete: 'Usuń'
            }
          },
          eventsLog: {
            title: 'Dziennik zdarzeń',
            close: 'Zamknij',
            headers: {
              date: 'Data',
              user: 'Użytkownik',
              event: 'Zdarzenie'
            },
            'description': {
              offer: {
                created: 'Utworzono',
                updated: 'Zaktualizowano',
                deleted: 'Usunięto',
                state: {
                  change: 'Zmiana stanu na {state}'
                }
              }
            }
          }
        }
      },
      jobOffer: {
        editOffer: 'Edycja oferty',
        newOffer: 'Nowa oferta',
        autoSaveDisabled: 'Zapis automatyczny, dla edycji oferty opublikowanej, jest wyłączony.',
        pleaseSelectTypeOfOffer: 'Proszę wybrać typ oferty.',
        continue: 'Kontynuuj',
        copyOfferAsNew: 'Użyj jako wzorca',
        publishNow: 'Opublikuj',
        moveToArchive: 'Przenieś do archiwum',
        conclude_offer: 'Rozstrzygnij konkurs',
        save: 'Zapisz',
        schedule: 'Zaplanuj publikacje',
        offerPreview: 'Podgląd oferty',
        autoSaveOk: 'Automatycznie zapisanie powiodło się.',
        autoSaveFailed: 'Coś poszło nie tak z automatycznym zapisem.',
        offerSaveOk: 'Oferta została zapisana',
        offerAttachSaveOk: 'Załączniki zostały zapisane',
        offerSaveFailed: 'Niestety oferta nie została zapisana!',
        offerAttachSaveFailed: 'Nie udało się zapisać załączników!',
        yesNoDialogPublishTitle: 'Publikowanie',
        yesNoDialogPublishMessage: 'Czy na pewno publikujemy ofertę z dzisiejszą datą?',
        yesNoDialogArchiveTitle: 'Archiwizacja',
        yesNoDialogArchiveMessage: 'Czy na pewno przenieść ofertę do archiwum?',
        required: 'Pola wymagane.'
      },
      validation: {
        url: 'Link do eRecruiter',
        urlSubject: 'adresem URL',
        dateOfDocumentsSubmissionDeadline: 'Data Terminu składania dokumentów',
        dateTermOfEmployment: 'Data Planowanego terminu zatrudnienia',
        dateOfDocumentsSubmissionDeadlineStartSmallLetter: 'data Terminu składania dokumentów',
        dateOfExpirationDate: 'Data wygaśnięcia oferty'
      }
    },
    login: {
      title: 'Zaloguj się',
      auth: {
        errors: {
          login: 'Błędny login lub hasło!'
        }
      }
    },
    jobOffers: {
      headerForTeachers: 'Oferty pracy dla nauczycieli',
      headerForMisc: 'Oferty pracy dla nienauczycieli',
      search: 'Wyszukaj',
      messages: {
        noOffers: 'Brak ofert'
      },
      buttons: {
        seeOffer: 'Zobacz szczegóły'
      },
      filters: {
        header: 'Filtry',
        buttons: {
          clear: 'Wyczyść',
          showFilters: 'Pokaż filtry',
          hideFilters: 'Schowaj filtry',
          showMore: 'Pokaż więcej',
          showLess: 'Pokaż mniej'
        }
      }
    },
    competitionsResults: {
      header: 'Rozstrzygnięcia',
      buttons: {
        downloadCompetitionResult: 'Pobierz rozstrzygnięcie'
      }
    },
    jobOffer: {
      labels: {
        summary: 'Podsumowanie'
      },
      buttons: {
        apply: 'Aplikuj'
      }
    },
    contact: {
      address: 'Adres',
      contactForm: 'Formularz kontaktowy',
      form: {
        fieldTopic: 'Temat wiadomości',
        fieldEmail: 'Twój adres email',
        fieldMsg: 'Treść wiadomości',
        sendMsg: 'Wyślij wiadomość',
        somethingWrong: 'Coś poszło nie tak, spróbuj ponownie później.',
        msgSent: 'Wiadomość została wysłana.',
        fieldAgreement: 'Wyrażam zgodę na przetwarzanie moich danych osobowych.'
      },
      validation: {
        email: 'Twój adres email',
        emailSubject: 'adresem email'
      }
    },
    notFoundError: {
      header: 'Motyla noga! Nic tu nie ma.'
    },
    unauthorizedError: {
      header: 'Brak dostępu!',
      button: 'Zaloguj się'
    },
    siteUnderConstruction: {
      header: 'Strona w trakcie tworzenia.'
    },
    siteUnderMaintenance: {
      header: 'Strona w trakcie konserwacji.',
      goToOldOp: 'Przejdź do aktualnego systemu'
    }
  },
  globals: {
    goBack: 'Powrót',
    login: 'Zaloguj się',
    accept: 'Akceptuję',
    attachments: 'Załączniki',
    cookies: {
      title: 'Ciasteczka',
      info: 'Nasze strony internetowe i oparte na nich usługi używają informacji zapisanych w plikach cookies. Korzystając z serwisu wyrażasz zgodę na używanie plików cookies zgodnie z aktualnymi ustawieniami przeglądarki, które możesz zmienić w dowolnej chwili.',
      infoUrlText: 'Ochrona danych osobowych'
    },
    continueAnyway: 'Kontynuuj mimo to',
    pwr: {
      info: {
        header: 'Politechnika Wrocławska',
        line1: 'Wybrzeże Wyspiańskiego 27',
        line2: '50-370 Wrocław',
        line3: 'NIP: 896-000-58-51',
        line4: 'REGON: 000001614'
      },
      contact: {
        header: 'Kontakt',
        line1: 'Dział Spraw Osobowych',
        line2: 'Sekcja Rekrutacji, Szkoleń i Spraw Socjalnych',
        line3: 'e-mail:',
        phone: '71 320 4670'
      },
      help: {
        title: 'Pomoc',
        email: 'pomoc+op@pwr.edu.pl'
      }
    }
  },
  validation: {
    invalid: 'Pole {field} jest nieprawidłowym {subject}.'
  },
  date: {
    today: 'dzisiaj',
    now: 'teraz'
  },
  layouts: {
    pwr: {
      administration: {
        navbar: {
          actions: {
            home: 'Strona główna',
            offers: 'Administracja Ofert Pracy',
            competitions: 'Rozstrzygnięcia',
            users: 'Użytkownicy i uprawnienia'
          }
        }
      },
      snackbar: {
        messages: {
          errors: {
            default: 'Operacja nie powiodła się!'
          }
        }
      },
      navbar: {
        actions: {
          jobOffersForTeacher: 'Oferty pracy dla nauczycieli',
          jobOffersForAdministration: 'Oferty pracy dla nienauczycieli',
          competitionsResults: 'Rozstrzygnięcia',
          contact: 'Kontakt',
          administration: 'Administracja'
        }
      }
    }
  },
  components: {
    pwr: {
      snackbar: {
        default: {
          titles: {
            default: '',
            success: 'Sukces!',
            error: 'Błąd!',
            info: 'Informacja!',
            warning: 'Uwaga!'
          }
        }
      }
    },
    op: {
      avatar: {
        logout: 'Wyloguj się',
        role: 'Rola'
      },
      textEditor: {
        placeholder: 'Zacznij pisać tutaj …',
        states: {
          init: 'Wczytywanie edytora.',
          error: 'Nie udało się wczytać edytora! Spróbuj odświeżyć stronę.'
        },
        buttons: {
          default: 'Wartość domyślna'
        }
      },
      textViewer: {
        states: {
          init: 'Wczytywanie podglądu.',
          error: 'Nie udało się wczytać podglądu! Spróbuj odświeżyć stronę.'
        }
      }
    },
    opNoResults: {
      message: 'Brak wyników'
    },
    dialogs: {
      yesNo: {
        buttons: {
          yes: 'Akceptuj',
          no: 'Anuluj'
        }
      }
    }
  },
  models: {
    offer: {
      scheduled: 'Zaplanowane'
    }
  },
  serviceWorker: {
    ready: 'Aplikacja jest serwowana z zapisanych wcześniej danych.',
    registered: 'Service worker został zarejestrowany.',
    cached: 'Zawartość strony została przygotowana do użytku offline.',
    updatefound: 'Pobieranie aktualizacji.',
    updated: 'Nowa zawartość dostępna; proszę odświeżyć stronę.',
    offline: 'Brak połączenia z internetem. Aplikacja pracuje w trybie offline.',
    error: 'Niespodziewany błąd podczas rejestrowania aplikacji.'
  },
  permissions: {
    noPermissions: 'Brak uprawnień do zarządzania stroną!'
  }
};

export default pl;
