export enum RoutesNames {
  E401 = 'E401',
  E404 = 'E404',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  SESSION_TIMEOUT = 'SessionTimeout',
  SITE_UNDER_CONSTRUCTION = 'SiteUnderConstruction',
  SITE_UNDER_MAINTENANCE = 'SiteUnderMaintenance'
}

export enum RoutesPaths {
  E401 = '/e401',
  E404 = '*',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SESSION_TIMEOUT = '/session-timeout',
  SITE_UNDER_CONSTRUCTION = '/site-under-construction',
  SITE_UNDER_MAINTENANCE = '/site-under-maintenance'
}
