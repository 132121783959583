import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';

export interface SnackbarState {
  isOpen: boolean;
  message: string;
  type: PwrSnackbarTypes;
  timeout: number;
}

export enum MutationTypes {
  SET_IS_OPEN = 'SET_IS_OPEN',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_TYPE = 'SET_TYPE',
  SET_TIMEOUT = 'SET_TIMEOUT'
}

export enum ActionTypes {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR'
}

export enum GetterTypes {
  GET_IS_OPEN = 'GET_IS_OPEN',
  GET_MESSAGE = 'GET_MESSAGE',
  GET_TYPE = 'GET_TYPE',
  GET_TIMEOUT = 'GET_TIMEOUT'
}

export interface SnackbarPayload {
  message: string;
  type?: PwrSnackbarTypes;
  timeout?: number;
}
