import { RouteConfig } from 'vue-router';
import { AuthGuard } from '@/modules/core/core-auth';
import RouterModuleInterface from '@/router/RouterModuleInterface';
import { RoutesNames, RoutesPaths } from '@/router/admin/types';

export default class AdminRoutes implements RouterModuleInterface {
  module(authGuard?: AuthGuard): RouteConfig {
    return {
      path: '/administration',
      component: () => import('@/views/Layouts/AdministrationLayout.vue'),
      beforeEnter: async (to, from, next) => {
        await authGuard?.continueIfAuthenticated(to, from, next);
      },
      children: [
        {
          path: RoutesPaths.HOME_ADMINISTRATION,
          name: RoutesNames.HOME_ADMINISTRATION,
          component: () => import('@/views/Layouts/EmptyLayout.vue'),
          beforeEnter: (to, from, next) => {
            // This is a default redirect to OFFERS_ADMINISTRATION view
            // In the future this can be deleted and Home view can be inserted as component
            next({ 'name': RoutesPaths.OFFERS_ADMINISTRATION });
          }
        },
        {
          path: RoutesPaths.OFFERS_ADMINISTRATION,
          name: RoutesNames.OFFERS_ADMINISTRATION,
          component: () => import('@/views/AdminPanel/AdminOffers/AdminOffersView.vue')
        },
        {
          path: RoutesPaths.SUPER_ADMIN_MENU,
          name: RoutesNames.SUPER_ADMIN_MENU,
          component : () => import('@/views/AdminPanel/SuperAdminMenu/SuperAdminMenu.vue')
        }
      ]
    };
  }
}
