import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { isString } from 'lodash';
import {
  DictGetterTypes,
  DictionariesService,
  DictionaryOfModules
} from '@/modules/core/core-dynamic-dictionaries';
import {
  GetterTypes as AuthGetterTypes,
  PermissionsHelper,
  TokenService
} from '@/modules/core/core-auth';
import { UserBaseModel } from '@/modules/core/core-types';
import { GetterTypes as LangGetterTypes } from '@/modules/LangSelector/store/types';
import SessionWatcherService from '@/modules/SessionWatcher/SessionWatcherService';
import { GetterTypes as OffersGetterTypes } from '@/store/offers/types';
import { ActionTypes as SnackbarActionTypes, SnackbarPayload } from '@/store/snackbar/types';
import { ActionTypes } from '@/store/types';
import RouterHelper from '@/helpers/routes/RouterHelper';
import Offer from '@/models/Offer';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import { RoutesNames as OfferRoutesNames } from '@/router/offers/types';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import store from '@/store';
import OffersHelpers from '@/helpers/offers/OffersHelpers';

const langNamespace = 'lang';
const dictNamespace = 'dictionaries';
const offersNamespace = 'offers';
const snackbarNamespace = 'snackbar';
const authNamespace = 'auth';

@Component
export default class PwrVue extends Vue {
  // Root vuex
  @Action(ActionTypes.ENABLE_SCROLL_UP_FAB) enableScrollUpFab!: () => void;
  @Action(ActionTypes.DISABLE_SCROLL_UP_FAB) disableScrollUpFab!: () => void;

  // Offers vuex
  @Getter(LangGetterTypes.GET_LANG, { namespace: langNamespace }) language!: string;

  @Getter(DictGetterTypes.GET_DICTIONARIES, { namespace: dictNamespace })
  dictionaries!: DictionaryOfModules;

  @Getter(OffersGetterTypes.GET_IS_PROCESSING, { namespace: offersNamespace })
  isProcessing!: boolean;

  @Getter(OffersGetterTypes.GET_IS_FETCHING, { namespace: offersNamespace })
  isFetching!: boolean;

  @Getter(OffersGetterTypes.GET_IS_SEARCHING, { namespace: offersNamespace })
  isSearching!: boolean;

  // Snackbar vuex
  @Action(SnackbarActionTypes.SHOW_SNACKBAR, { namespace: snackbarNamespace }) showSnackbar!: (
    payload: SnackbarPayload
  ) => void;

  // User
  @Getter(AuthGetterTypes.GET_LOGGED_USER, { namespace: authNamespace })
  user!: UserBaseModel | null;

  /**
   * Wrapper for hasRole form core-auth permission helper
   */
  public async userHasRole(role: string, user: UserBaseModel | null = null): Promise<boolean> {
    return PermissionsHelper.hasRole(role, user);
  }

  public async can(
    module: string,
    action: string,
    scope: string = '',
    user: UserBaseModel | null = null
  ): Promise<boolean> {
    return PermissionsHelper.can(module, action, scope, user);
  }

  public desktopBreakpoints = ['md', 'lg', 'xl'];

  public routesHelper = new RouterHelper(this.$router, store);
  public offersHelper = new OffersHelpers(this.$i18n, this.$router, store);

  public showErrorSnackbar(message?: string): void {
    message = message === 'The given data was invalid.' ? undefined : message;
    this.showSnackbar({
      type: PwrSnackbarTypes.ERROR,
      message:
        message ?? ((this.$t('layouts.pwr.snackbar.messages.errors.default') as string) || 'Error!')
    });
  }

  get areDictionariesLoaded(): boolean {
    return Object.keys(this.dictionaries).length > 0;
  }

  public get isDesktop(): boolean {
    const breakpoint = this.$vuetify.breakpoint.name;
    return this.desktopBreakpoints.includes(breakpoint);
  }

  public getLabelByPath(path: string, offer: Offer | null = null): string | null {
    const defResult = DictionariesService.getLabel(path);

    if (!defResult && offer && offer.fallbackDict) {
      return DictionariesService.getLabel(path, { 'offer': offer.fallbackDict });
    }
    return defResult;
  }

  public getConstantByPath(path: string, offer: Offer | null = null): string | null {
    const defResult = DictionariesService.getConstant(path);

    if (!defResult && offer && offer.fallbackDict) {
      return DictionariesService.getConstant(path, { 'offer': offer.fallbackDict });
    }

    return defResult;
  }

  public getLabels(path: string, offer: Offer | null = null): string[] | null {
    const defResult = DictionariesService.getLabels(path);

    if (!defResult && offer && offer.fallbackDict) {
      return DictionariesService.getLabels(path, { 'offer': offer.fallbackDict });
    }

    return defResult;
  }

  public getItems(type: string, permType: string = ''): any[] {
    const items: { text: string; value: number }[] = [];
    const itemLabels = this.getLabels(`offer.${type}`);

    if (permType === '') {
      permType = type;
    }

    if (itemLabels === null || itemLabels.length === 0) {
      return items;
    }

    if (!this.user || !this.user.permissionsParsed || !this.user.permissionsParsed[permType]) {
      return items;
    }

    if (
      Object.keys(this.user.permissionsParsed[permType]).includes('*') ||
      this.user.permissionsParsed[permType].read.includes('*')
    ) {
      Object.keys(itemLabels).forEach((key: string) => {
        const parsedKey = Number(key);
        items.push({ text: itemLabels[parsedKey], value: parsedKey });
      });
      return items;
    }

    Object.keys(itemLabels).forEach((value) => {
      // @ts-ignore
      if (!this.user.permissionsParsed[permType].read.includes(value)) {
        delete itemLabels[+value];
      }
    });

    Object.keys(itemLabels).forEach((key: string) => {
      const parsedKey = Number(key);
      items.push({ text: itemLabels[parsedKey], value: parsedKey });
    });

    // if (itemLabels) {
    //   if (
    //     this.user &&
    //     this.user.permissionsParsed &&
    //     this.user.permissionsParsed[permType] &&
    //     !Object.keys(this.user.permissionsParsed[permType]).includes('*')
    //   ) {
    //     if (!this.user.permissionsParsed[permType].read.includes('*') && itemLabels) {
    //       Object.keys(itemLabels).forEach((value) => {
    //         // @ts-ignore
    //         if (!this.user.permissionsParsed[permType].read.includes(value)) {
    //           delete itemLabels[+value];
    //         }
    //       });
    //     }
    //   }
    //   Object.keys(itemLabels).forEach((key: string) => {
    //     const parsedKey = Number(key);
    //     items.push({ text: itemLabels[parsedKey], value: parsedKey });
    //   });
    // }

    return items;
  }

  /**
   * Reset no activity timer
   */
  public resetAfkTimer(): void {
    SessionWatcherService.refreshCounter();
  }

  public isProp(prop: boolean | string): boolean {
    if (typeof prop === 'string') {
      return true;
    }

    return prop;
  }

  public getDatetimeDate(datetime: string): string {
    return datetime.split(' ')[0];
  }

  public getDatetimeTime(datetime: string): string {
    return datetime.split(' ')[1];
  }

  public getDatetimeTimeWithoutSeconds(datetime: string): string {
    return datetime.split(' ')[1].substr(0, 5);
  }

  public async goBack(): Promise<void> {
    await this.routesHelper.goToLastValidRoute(this.$router);
  }

  private async goToLogin(): Promise<void> {
    await this.routesHelper.go(GlobalRoutesNames.LOGIN);
  }

  public isUserLoggedIn(): boolean {
    return TokenService.isAccessTokenValid();
  }

  public isLocalDevelopment(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  public isStagingEnv(): boolean {
    return window.location.href.startsWith('https://op-dev');
  }

  private async goToLogout(): Promise<void> {
    await this.routesHelper.go(GlobalRoutesNames.LOGOUT);
  }

  public async dispatchOfferView(
    offer: Offer | string | undefined,
    routeName: OfferRoutesNames
  ): Promise<void> {
    if (offer) {
      await this.$router.push({
        name: routeName,
        params: { uuid: isString(offer) ? offer : offer.uuid || '' }
      });
    }

    if (routeName === OfferRoutesNames.NEW) {
      await this.$router.push({
        name: OfferRoutesNames.NEW
      });
    }
  }
}
