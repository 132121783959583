import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pl from 'vuetify/src/locale/pl';
import en from 'vuetify/src/locale/en';
import LangSelectorService from '@/modules/LangSelector/LangSelectorService';
import icons from './icons';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pl, en },
    current: LangSelectorService.lang
  },
  icons: {
    iconfont: 'faSvg',
    values: icons
  },
  theme: {
    themes: {
      light: {
        primary: '#b2322b',
        secondary: '#FDCC99',
        accent: '#000',
        error: '#b2322b',
        info: '#428bc1',
        success: '#529746',
        warning: '#ff992f',
        gray: '#333333'
      }
    }
  }
});
