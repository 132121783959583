import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { DictionaryOfModules, Hook , ActionTypes, DictionariesState, GetterTypes, MutationTypes } from '../types';
import DictionariesService from '../services/DictionariesService';

const namespaced: boolean = true;

const state: DictionariesState = {
  dictionaries: {}
};

const mutations: MutationTree<DictionariesState> = {
  [MutationTypes.SET_DICTIONARIES](state, payload: DictionaryOfModules): void {
    state.dictionaries = payload;
  }
};

const actions: ActionTree<DictionariesState, any> = {
  async [ActionTypes.FETCH_DICTIONARIES]({ commit }): Promise<void> {
    const dictionaries: DictionaryOfModules = {};

    for (let i = 0; i < DictionariesService.hooks.length; i++) {
      const hook: Hook = DictionariesService.hooks[i];
      // eslint-disable-next-line no-await-in-loop
      const response = await hook.callback();
      dictionaries[hook.key] = DictionariesService.parseArrayOfRawDicts(response);
    }

    commit(MutationTypes.SET_DICTIONARIES, dictionaries);
  },
  [ActionTypes.CLEAR_DICTIONARIES]({ commit }): void {
    commit(MutationTypes.SET_DICTIONARIES, {});
  }
};

const getters: GetterTree<DictionariesState, any> = {
  [GetterTypes.GET_DICTIONARIES](state): DictionaryOfModules {
    return state.dictionaries;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
