import { AxiosInstance } from 'axios';
import VueRouter from 'vue-router';
import { ErrorHelper, LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import { PermissionsHelper, TokenService } from '@/modules/core/core-auth';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import MaintenanceService from '@/modules/Maintenance/services/MaintenanceService';

const omitMaintenance = async () => {
  const secret = await MaintenanceService.secret();

  if (secret) {
    LoggingHelper.log('Getting maintenance cookie', LogLevel.INFO, ['site', 'omitMaintenance']);
    MaintenanceService.cookie(secret);
  }
};

const maintenanceInterceptor = (
  axiosInstance: AxiosInstance,
  router: VueRouter,
  whitelist: string[] = []
): void => {
  axiosInstance.interceptors.response.use(
    async (response) => {
      if (router.currentRoute.name === 'SiteUnderMaintenanceView.vue') {
        await router.push({ name: 'Home' });
      }

      return Promise.resolve(response);
    },
    async (error: unknown) => {
      if (error) {
        const axiosError = ErrorHelper.getAxiosError(error);

        if (axiosError) {
          // Detecting maintenance mode
          if (axiosError.status === 503) {
            LoggingHelper.log('Detected backend in maintenance mode!', LogLevel.INFO, [
              'site',
              'maintenanceInterceptor'
            ]);

            const currentRouteName = router.currentRoute.name ?? '';

            if (!whitelist.includes(currentRouteName)) {
              // User is still logged in
              if (TokenService.isAccessTokenValid()) {
                if (await PermissionsHelper.hasRole('super_administrator')) {
                  LoggingHelper.log(
                    'Detected Super Admin, trying to omit maintenance',
                    LogLevel.INFO,
                    ['site', 'maintenanceInterceptor']
                  );

                  await omitMaintenance();
                }
              } else {
                // Makes sure that someone accidentally gets access to site when it's under maintenance
                await MaintenanceService.deleteCookie();
              }

              LoggingHelper.log('Redirecting to site under maintenance view.', LogLevel.INFO, [
                'site',
                'maintenanceInterceptor'
              ]);

              await router.push({ name: GlobalRoutesNames.SITE_UNDER_MAINTENANCE });
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default maintenanceInterceptor;
