import { IVueI18n } from 'vue-i18n';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import Offer from '@/models/Offer';
import UnauthorizedException from '@/exceptions/auth/UnauthorizedException';
import NotFoundException from '@/exceptions/http/NotFoundException';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import { ActionTypes as OffersActionTypes } from '@/store/offers/types';
import { ActionTypes as SnackbarActionTypes } from '@/store/snackbar/types';
import RouterHelper from '@/helpers/routes/RouterHelper';

const offersNamespace = 'offers';
const snackbarNamespace = 'snackbar';

export default class OffersHelpers {
  private routerHelper: RouterHelper;

  constructor(private i18n: IVueI18n, private router: VueRouter, private store: Store<any>) {
    this.routerHelper = new RouterHelper(this.router, this.store);
  }

  public async recoverOffer(uuid: string): Promise<Offer | undefined> {
    try {
      const fetchedOffer = await this.store.dispatch(
        `${offersNamespace}/${OffersActionTypes.FETCH_OFFER}`,
        uuid
      );

      if (fetchedOffer) {
        return fetchedOffer;
      }

      await this.store.dispatch(`${snackbarNamespace}/${SnackbarActionTypes.SHOW_SNACKBAR}`, {
        color: 'red',
        message: 'Offer could not be fetched'
      });
    } catch (e: unknown) {
      if ((e as UnauthorizedException).isUnauthorizedException) {
        await this.routerHelper.go(GlobalRoutesNames.E401);
      }

      if ((e as NotFoundException).isNotFoundException) {
        await this.routerHelper.go(SiteRoutesNames.HOME);
      }
    }

    return undefined;
  }
}
