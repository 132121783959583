import axios, { AxiosError, AxiosResponse } from 'axios';
import LoggingHelper from './LoggingHelper';

export default class ErrorHelper {
  public static getAxiosError(error: unknown): AxiosResponse | null {
    try {
      if (axios.isAxiosError(error)) {
        return (error as AxiosError).response as AxiosResponse;
      }

      return error as AxiosResponse;
    } catch (e: unknown) {
      LoggingHelper.log(error);
    }

    return null;
  }
}
