import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LangSelectorService from '@/modules/LangSelector/LangSelectorService';

import pwrFileUploaderLangPl from '@/components/Pwr/PwrFiles/lang/pl';
import pwrFileUploaderLangEn from '@/components/Pwr/PwrFiles/lang/en';
import internationalization from '../lang/index';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: LangSelectorService.lang,
  fallbackLocale: 'en',
  messages: internationalization
});

i18n.mergeLocaleMessage('pl', pwrFileUploaderLangPl);
i18n.mergeLocaleMessage('en', pwrFileUploaderLangEn);

export default i18n;
