import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Route } from 'vue-router';
import RouterHelperProvider from '@/helpers/routes/RouterHelperProvider';
import { GetterTypes, RouterState, ActionTypes, PushActionPayload, MutationTypes } from './types';

const namespaced: boolean = true;
const lastSessionRouteNameStorageKey = 'LAST_SESSION_ROUTE_NAME';

const state: RouterState = {
  lastRoute: undefined,
  history: [],
  lastSessionRouteName: undefined
};

const mutations: MutationTree<RouterState> = {
  [MutationTypes.SET_LAST_ROUTE](state, payload: Route) {
    state.lastRoute = payload;
  },
  [MutationTypes.SET_HISTORY](state, payload: Route[]) {
    state.history = payload;
  },
  [MutationTypes.SET_LAST_SESSION_ROUTE_NAME](state, payload: string) {
    state.lastSessionRouteName = payload;
  }
};

const actions: ActionTree<RouterState, any> = {
  [ActionTypes.PUSH]({ state, commit }, payload: PushActionPayload): void {
    if (!RouterHelperProvider.routesNamesToAvoidGoingBackTo.includes(payload.from?.name || '')) {
      commit(MutationTypes.SET_LAST_ROUTE, payload.from);

      state.history.push(payload.from);
      commit(MutationTypes.SET_HISTORY, state.history);
    }

    if (!RouterHelperProvider.routesNamesToAvoidGoingBackTo.includes(payload.to?.name || '')) {
      localStorage.setItem(lastSessionRouteNameStorageKey, payload.to?.name || '');
      commit(MutationTypes.SET_LAST_SESSION_ROUTE_NAME, payload.to?.name || '');
    }
  }
};

const getters: GetterTree<RouterState, any> = {
  [GetterTypes.GET_LAST_ROUTE](state): Route | undefined {
    return state.lastRoute;
  },
  [GetterTypes.GET_HISTORY](state): Route[] {
    return state.history;
  },
  [GetterTypes.GET_LAST_SESSION_ROUTE_NAME](state): string {
    const lastSessionRouteName =
      state.lastSessionRouteName || localStorage.getItem(lastSessionRouteNameStorageKey);

    if (
      lastSessionRouteName &&
      !RouterHelperProvider.routesNamesToAvoidGoingBackTo.includes(lastSessionRouteName)
    ) {
      return lastSessionRouteName;
    }

    return RouterHelperProvider.fallbackRouteName;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
