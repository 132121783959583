import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import offers from './offers';
import router from './router';
import snackbar from './snackbar';
import { ActionTypes, GetterTypes, MutationTypes, RootState } from './types';

Vue.use(Vuex);

const state: RootState = {
  enableScrollUpFab: true
};

const mutations: MutationTree<RootState> = {
  [MutationTypes.SET_ENABLE_SCROLL_UP_FAB](state, payload: boolean): void {
    state.enableScrollUpFab = payload;
  }
};

const actions: ActionTree<RootState, any> = {
  [ActionTypes.ENABLE_SCROLL_UP_FAB]({ commit }): void {
    commit(MutationTypes.SET_ENABLE_SCROLL_UP_FAB, true);
  },
  [ActionTypes.DISABLE_SCROLL_UP_FAB]({ commit }): void {
    commit(MutationTypes.SET_ENABLE_SCROLL_UP_FAB, false);
  }
};

const getters: GetterTree<RootState, any> = {
  [GetterTypes.GET_ENABLE_SCROLL_UP_FAB](state): boolean {
    return state.enableScrollUpFab;
  }
};

const store: StoreOptions<RootState> = {
  modules: {
    offers,
    router,
    snackbar
  },
  state,
  getters,
  mutations,
  actions
};

export default new Vuex.Store<RootState>(store);
