import api from '@/modules/core/core-api';
import { ProcessEnvBaseModel } from '@/modules/core/core-types';

export default class MaintenanceService {
  private static groupRoot = 'maintenance';

  /**
   * Disables maintenance mode
   */
  public static async up(): Promise<boolean> {
    const response = await api.get(`${this.groupRoot}/up`);
    const { data } = response;
    return data.message === 'OK';
  }

  /**
   * Enables maintenance mode
   */
  public static async down(): Promise<string> {
    const response = await api.get(`${this.groupRoot}/down`);
    const { data } = response;
    return data.secret ?? '';
  }

  /**
   * Secret to omit maintenance mode
   */
  public static async secret(): Promise<string> {
    const response = await api.get(`${this.groupRoot}/secret`);
    const { data } = response;
    return data.secret ?? '';
  }

  /**
   * Cookie to omit maintenance mode
   */
  public static cookie(secret: string): void {
    window.location.href = `${
      (process.env as unknown as ProcessEnvBaseModel).VUE_APP_BACKEND_URL
    }/${secret}`;
  }

  /**
   * Deletes laravel_maintenance cookie
   */
  public static async deleteCookie(): Promise<void> {
    await api.get(`${this.groupRoot}/delete-bypass`);
  }
}
