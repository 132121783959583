import { UserBaseModel } from '@/modules/core/core-types';
import AuthService from '../services/AuthService';

export default class PermissionsHelper {
  /**
   * Validates user's role
   *
   * @param role Role name or constant
   * @param user Optional user, default one comes form vuex
   */
  public static async hasRole(role: string | string[], user: UserBaseModel | null = null): Promise<boolean> {
    const currentUser: UserBaseModel | null = user ?? (await AuthService.getUserFromStore());

    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      return false;
    }

    for (let i = 0; i < currentUser.roles.length; i++) {
      if (currentUser.roles[i].name === role || currentUser.roles[i].const === role) {
        return true;
      }
    }

    return false;
  }

  /**
   * If user hase all roles
   *
   * @param roles Roles names or constant
   * @param user Optional user, default one comes form vuex
   */
  public static async hasRoles(roles: string[], user: UserBaseModel | null = null): Promise<boolean> {
    const currentUser: UserBaseModel | null = user ?? (await AuthService.getUserFromStore());
    let result = true;

    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      return false;
    }

    roles.forEach((role) => {
      // @ts-ignore
      for (let i = 0; i < currentUser.roles.length; i++) {
        // @ts-ignore
        const roleName = currentUser.roles[i].name === role;
        // @ts-ignore
        const roleConst = currentUser.roles[i].const === role;

        if (!roleName && !roleConst) {
          result = false;
        }
      }
    });

    return result;
  }

  /**
   * If user at least one role
   *
   * @param roles Roles names or constant
   * @param user Optional user, default one comes form vuex
   */
  public static async hasAtLeastOneRole(roles: string[], user: UserBaseModel | null = null): Promise<boolean> {
    const currentUser: UserBaseModel | null = user ?? (await AuthService.getUserFromStore());
    let result = false;

    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      return false;
    }

    roles.forEach((role) => {
      // @ts-ignore
      for (let i = 0; i < currentUser.roles.length; i++) {
        // @ts-ignore
        const roleName = currentUser.roles[i].name === role;
        // @ts-ignore
        const roleConst = currentUser.roles[i].const === role;

        if (roleName || roleConst) {
          result = true;
        }
      }
    });

    return result;
  }

  /**
   * Validates user's permissions
   *
   * @param module Module form permission string 'MODULE.action.scope'
   * @param action Action form permission string 'module.ACTION.scope'
   * @param scope Scope form permission string 'module.action.SCOPE'
   * @param user Optional user, default one comes form vuex
   */
  public static async can(
    module: string,
    action: string,
    scope: string = '',
    user: UserBaseModel | null = null
  ): Promise<boolean> {
    if (module.length === 0 || action.length === 0) {
      return false;
    }

    const currentUser: UserBaseModel | null = user ?? (await AuthService.getUserFromStore());

    if (currentUser?.permissionsParsed) {
      const userModules: string[] = Object.keys(currentUser.permissionsParsed);

      if (!userModules.includes(module)) {
        return false;
      }

      const userActions = Object.keys(currentUser.permissionsParsed[module]);

      if (action.length === 0) {
        return false;
      }

      if (userActions.includes('*')) {
        return true;
      }

      if (!userActions.includes(action)) {
        return false;
      }

      const userScope = currentUser.permissionsParsed[module][action];

      if (scope.length === 0) {
        return false;
      }

      if (userScope.includes(scope) || userScope.includes('*')) {
        return true;
      }
    }

    return false;
  }
}
