import { IVueI18n } from 'vue-i18n';
import Vuetify from 'vuetify/lib/framework';
import { Store } from 'vuex';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import { ActionTypes } from '@/modules/LangSelector/store/types';

export default class LangSelectorService {
  private static readonly LANG_ID: string = 'LANG_ID';
  public static defaultLang: string = 'pl';
  public static i18n: IVueI18n;
  public static vuetify: Vuetify;
  public static store: Store<any>;
  public static changeLangListeners: { (lang: string): void }[] = [];

  static set lang(lang: string) {
    LoggingHelper.log(`Setting language: ${lang}`, LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorService',
      'lang setter'
    ]);

    if (this.i18n) {
      LoggingHelper.log('Updating lang in i18n', LogLevel.DEBUG, [
        'app',
        'modules',
        'LangSelectorService',
        'lang setter'
      ]);

      this.i18n.locale = lang;

      if (lang === 'pl') {
        this.i18n.fallbackLocale = 'en';
      } else {
        this.i18n.fallbackLocale = 'pl';
      }
    }

    if (this.vuetify) {
      LoggingHelper.log('Updating lang in vuetify', LogLevel.DEBUG, [
        'app',
        'modules',
        'LangSelectorService',
        'lang setter'
      ]);

      this.vuetify.preset.lang.current = lang;
    }

    localStorage.setItem(this.LANG_ID, lang);

    if (this.store) {
      this.store.dispatch(`lang/${ActionTypes.SELECT_LANG}`, lang);
    }

    LangSelectorService.changeLangListeners.forEach((callback: { (lang: string): void }) =>
      callback(lang)
    );
  }

  static get lang(): string {
    const browserLanguage = navigator.language.substr(0, 2);
    const storageLanguage = localStorage.getItem(this.LANG_ID);

    if (storageLanguage) {
      LoggingHelper.log(`Retrieved language from storage: ${storageLanguage}`, LogLevel.DEBUG, [
        'app',
        'modules',
        'LangSelectorService',
        'lang getter'
      ]);

      return storageLanguage;
    }

    if (browserLanguage) {
      LoggingHelper.log(
        `Retrieved language from browser info: ${browserLanguage}`,
        LogLevel.DEBUG,
        ['app', 'modules', 'LangSelectorService', 'lang getter']
      );

      return browserLanguage;
    }

    LoggingHelper.log(`Retrieved default language: ${this.defaultLang}`, LogLevel.DEBUG, [
      'app',
      'modules',
      'LangSelectorService',
      'lang getter'
    ]);

    return this.defaultLang;
  }
}
