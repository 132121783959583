import moment, {Moment} from 'moment';
import CoreInputValidatorsProvider from './CoreInputValidatorsProvider';

export default class CoreInputValidators {
  public static minRule(
    value: string | number,
    minLength: number,
    orEqual: boolean = false,
    label: string | null = null
  ): boolean | string {
    if (!label) {
      label = this.getLabel();
    }

    const isNumeric = typeof value === 'number';

    if (isNumeric) {
      return (
        (orEqual ? value >= minLength : value > minLength) ||
        (CoreInputValidatorsProvider.i18n.t('coreInputValidator.minRuleNumber', {
          label,
          minLength
        }) as string)
      );
    }
    return (
      (value &&
        (orEqual
          ? (value as string).length >= minLength
          : (value as string).length > minLength)) ||
      (CoreInputValidatorsProvider.i18n.tc('coreInputValidator.minRuleString', minLength, {
        label
      }) as string)
    );

  }

  public static maxRule(
    value: string | number,
    maxLength: number,
    orEqual: boolean = false,
    label: string | null = null
  ): boolean | string {
    if (!label) {
      label = this.getLabel();
    }

    const isNumeric = typeof value === 'number';

    if (isNumeric) {
      return (
        (orEqual ? value <= maxLength : value < maxLength) ||
        (CoreInputValidatorsProvider.i18n.t('coreInputValidator.maxRuleNumber', {
          label,
          maxLength
        }) as string)
      );
    }
    return (
      (value &&
        (orEqual
          ? (value as string).length <= maxLength
          : (value as string).length < maxLength)) ||
      (CoreInputValidatorsProvider.i18n.tc('coreInputValidator.maxRuleString', maxLength, {
        label
      }) as string)
    );

  }

  public static isNumberRule(value: string, label: string | null = null): boolean | string {
    if (!label) {
      label = this.getLabel();
    }

    return (
      !value ||
      !Number.isNaN(parseInt(value, 10)) ||
      (CoreInputValidatorsProvider.i18n.t('coreInputValidator.isNumberRule', {label}) as string)
    );
  }

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static required(value: any, label: string | null = null): boolean | string {
    if (!label) {
      label = this.getLabel();
    }

    return (
      !!value ||
      (CoreInputValidatorsProvider.i18n.t('coreInputValidator.required', {label}) as string)
    );
  }

  public static minDate(
    value: string | Moment,
    minDate: string | Moment,
    orEqual: boolean = false,
    label: string | null = null,
    dateLabel: string | null = null
  ): boolean | string {
    if (!label) {
      label = this.getDateLabel();
    }

    const v: Moment = typeof value === 'string' ? moment(value) : value;
    const mD: Moment = typeof minDate === 'string' ? moment(minDate) : minDate;

    return (
      (orEqual ? v >= mD : v > mD) ||
      (CoreInputValidatorsProvider.i18n.t('coreInputValidator.minDate', {
        label,
        date: dateLabel ?? mD.format('yyyy-MM-DD')
      }) as string)
    );
  }

  public static maxDate(
    value: string | Moment,
    maxDate: string | Moment,
    orEqual: boolean = false,
    label: string | null = null,
    dateLabel: string | null = null
  ): boolean | string {
    if (!label) {
      label = this.getDateLabel();
    }

    const v: Moment = typeof value === 'string' ? moment(value) : value;
    const mD: Moment = typeof maxDate === 'string' ? moment(maxDate) : maxDate;

    return (
      (orEqual ? v <= mD : v < mD) ||
      (CoreInputValidatorsProvider.i18n.t('coreInputValidator.maxDate', {
        label,
        date: dateLabel ?? mD.format('yyyy-MM-DD')
      }) as string)
    );
  }

  private static getLabel(): string {
    return CoreInputValidatorsProvider.i18n.t('coreInputValidator.input') as string;
  }

  private static getDateLabel(): string {
    return CoreInputValidatorsProvider.i18n.t('coreInputValidator.date') as string;
  }
}
