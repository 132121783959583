import axios, { AxiosInstance } from 'axios';
import VueRouter from 'vue-router';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import TokenService from '../services/TokenService';
import TokenUserResponse from '../types/TokenUserResponse';
import AuthService from '../services/AuthService';
import AuthGuardOptions from '../types/AuthGuardOptions';

const errorInterceptor = (
  axiosInstance: AxiosInstance,
  routes: AuthGuardOptions | undefined = undefined,
  router: VueRouter | undefined = undefined
): void => {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    async (error) => {
      if (!error) {
        return false;
      }

      if (error && !error.response) {
        return Promise.reject(error);
      }

      if (error.response.status) {
        switch (error.response.status) {
          case 401: {
            LoggingHelper.log('Known error', LogLevel.DEBUG, [
              'core-auth',
              'ErrorInterceptor',
              'case 401'
            ]);

            axiosInstance.interceptors.response.eject(interceptor);

            const accessToken = TokenService.getAccessTokenFromStorage();
            if (!accessToken) {
              LoggingHelper.log('There are no tokens in storage, cannot refresh.', LogLevel.DEBUG, [
                'core-auth',
                'ErrorInterceptor'
              ]);

              if (routes && router) {
                await router.push({ name: routes.unauthorizedRouteName });
              }

              return Promise.reject(error.response);
            }

            if (TokenService.wasSsoLogin()) {
              if (routes && router) {
                await router.push({ name: routes.unauthorizedRouteName });
              }

              return Promise.reject('Unauthorized');
            }

            try {
              LoggingHelper.log('Attempting to refresh tokens', LogLevel.DEBUG, [
                'core-auth',
                'ErrorInterceptor'
              ]);

              const data: TokenUserResponse | null = await AuthService.refresh();

              if (data && data.tokens) {
                // eslint-disable-next-line no-param-reassign
                error.config.headers.Authorization = `Bearer ${data.tokens.accessToken}`;
                LoggingHelper.log('Tokens refreshed, setting new AccessToken.', LogLevel.DEBUG, [
                  'core-auth',
                  'ErrorInterceptor'
                ]);
              } else {
                LoggingHelper.log(
                  'Refresh failed! No new tokens returned from backend.',
                  LogLevel.DEBUG,
                  ['core-auth', 'ErrorInterceptor']
                );
                TokenService.removeTokensFromLocalStorage();

                if (routes && router) {
                  await router.push({ name: routes.unauthorizedRouteName });
                }

                return Promise.reject(new Error('Unable to refresh tokens.'));
              }

              return axiosInstance(error.response.config);
            } catch (err: unknown) {
              LoggingHelper.log('Refresh failed!', LogLevel.DEBUG, [
                'core-auth',
                'ErrorInterceptor'
              ]);
              LoggingHelper.log(err, LogLevel.ERROR, [
                'core-auth',
                'ErrorInterceptor',
                'Refresh exception catch'
              ]);

              TokenService.removeTokensFromLocalStorage();

              if (routes && router) {
                await router.push({ name: routes.unauthorizedRouteName });
              }

              if (axios.isAxiosError(err)) {
                return Promise.reject(err.response);
              }

              return Promise.reject(err);
            }
          }
          default:
            LoggingHelper.log('Unknown error, continuing ...', LogLevel.DEBUG, [
              'core-auth',
              'ErrorInterceptor'
            ]);
            return Promise.reject(error.response);
        }
      }

      return false;
    }
  );
};

export default errorInterceptor;
