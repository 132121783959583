import { AxiosInstance } from 'axios';
import LangSelectorService from '@/modules/LangSelector/LangSelectorService';

const langSelectorInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.common['Accept-Language'] = LangSelectorService.lang;
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export default langSelectorInterceptor;
