export default class PermissionsParser {
  private readonly permissions: string[] = [];

  constructor(permissions: string[]) {
    this.permissions = permissions;
  }

  public parse(): { [key: string]: any } {
    const permissions: { [key: string]: any } = {};
    for (let i = 0; i < this.permissions.length; i++) {
      const permSplitted: string[] = this.permissions[i].split('.');

      const module: string = permSplitted[0];
      const actions: string = permSplitted[1];
      const scopes: string | null = permSplitted[2] ?? null;

      if (!Object.keys(permissions).includes(module)) {
        permissions[module] = {};
      }

      if (Object.keys(permissions[module]).includes('*')) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (actions.includes('*')) {
        permissions[module]['*'] = {};
      } else if (actions.includes(',')) {
        const actionsSplitted: string[] = actions.split(',');

        actionsSplitted.forEach((actionString: string) => {
          permissions[module][actionString] = this.parseAction(actionString, scopes);
        });
      } else {
        if (scopes && scopes.includes('*')) {
          permissions[module][actions] = ['*'];
        }
        if (!Object.keys(permissions[module]).includes(actions)) {
          permissions[module][actions] = [];
        }
        permissions[module][actions] = permissions[module][actions].concat(
          this.parseAction(permSplitted[1], scopes)
        );
        if (permissions[module][actions].includes('*')) {
          permissions[module][actions] = ['*'];
        }
      }
    }

    return permissions;
  }

  private parseAction(actionStr: string, scopeString: string | null): string[] {
    let action: string[] = [];

    if (scopeString) {
      if (scopeString.includes('*')) {
        action = ['*'];
      } else if (scopeString.includes(',')) {
        const scopesSplitted: string[] = scopeString.split(',');

        scopesSplitted.forEach((scopeString: string) => {
          action.push(scopeString);
        });
      } else {
        action = [scopeString];
      }
    }

    return action;
  }
}
