import { NavigationGuard, Route } from 'vue-router';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';
import TokenService from '../services/TokenService';
import AuthGuardOptions from '../types/AuthGuardOptions';
import AuthService from '../services/AuthService';

type Next = Parameters<NavigationGuard>[2];

export default class AuthGuard {
  options: AuthGuardOptions;

  constructor(options: AuthGuardOptions) {
    this.options = options;
  }

  async continueIfAuthenticated(to: Route, from: Route, next: Next): Promise<void> {
    if (to.name === this.options.ssoPostAuthRouteName) {
      LoggingHelper.log('Detected post sso login, continuing ... ', LogLevel.DEBUG, [
        'core-auth',
        'AuthGuard',
        'continueIfAuthenticated'
      ]);

      next();
      return;
    }

    if (!TokenService.isAccessTokenValid()) {
      LoggingHelper.log('Token not valid!', LogLevel.DEBUG, ['core-auth', 'AuthGuard', 'continueIfAuthenticated']);
      await AuthService.refresh();
    }

    if (TokenService.isAccessTokenValid()) {
      LoggingHelper.log('Tokens validated, continuing ...', LogLevel.DEBUG, [
        'core-auth',
        'AuthGuard',
        'continueIfAuthenticated'
      ]);
      next();
      return;
    }

    LoggingHelper.log(`User not authenticated! Redirecting to ${  this.options.unauthorizedRouteName}`, LogLevel.DEBUG, [
      'core-auth',
      'AuthGuard',
      'continueIfAuthenticated'
    ]);
    next({ name: this.options.unauthorizedRouteName });
  }
}
