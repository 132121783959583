import { RouteConfig } from 'vue-router';
import { AuthService } from '@/modules/core/core-auth';
import { ProcessEnvBaseModel } from '@/modules/core/core-types';
import RouterModuleInterface from '@/router/RouterModuleInterface';
import MaintenanceService from '@/modules/Maintenance/services/MaintenanceService';
import { RoutesNames, RoutesPaths } from '@/router/global/types';

const LoginView = () => import('@/views/Login/LoginView.vue');
const SiteUnderConstructionView = () => import('@/views/Globals/SiteUnderConstructionView.vue');
const SiteUnderMaintenanceView = () => import('@/views/Globals/SiteUnderMaintenanceView.vue');
const E401View = () => import('@/views/Globals/E401View.vue');
const E404View = () => import('@/views/Globals/E404View.vue');
const SessionTimeoutView = () => import('@/views/Globals/SessionTimeoutView.vue');

export default class GlobalRoutes implements RouterModuleInterface {
  module(): RouteConfig[] {
    return [
      {
        path: RoutesPaths.LOGIN,
        name: RoutesNames.LOGIN,
        component: LoginView
      },
      {
        path: RoutesPaths.LOGOUT,
        name: RoutesNames.LOGOUT,
        beforeEnter: async () => {
          await AuthService.logout();

          // Makes sure that someone accidentally gets access to site when it's under maintenance
          await MaintenanceService.deleteCookie();

          // Forces reload
          window.location.replace((process.env as unknown as ProcessEnvBaseModel).VUE_APP_BASE_URL);
        }
      },
      {
        path: RoutesPaths.SESSION_TIMEOUT,
        name: RoutesNames.SESSION_TIMEOUT,
        component: SessionTimeoutView
      },
      {
        path: RoutesPaths.SITE_UNDER_CONSTRUCTION,
        name: RoutesNames.SITE_UNDER_CONSTRUCTION,
        component: SiteUnderConstructionView
      },
      {
        path: RoutesPaths.SITE_UNDER_MAINTENANCE,
        name: RoutesNames.SITE_UNDER_MAINTENANCE,
        component: SiteUnderMaintenanceView
      },
      {
        path: RoutesPaths.E401,
        name: RoutesNames.E401,
        component: E401View
      },
      {
        path: RoutesPaths.E404,
        name: RoutesNames.E404,
        component: E404View
      }
    ];
  }
}
