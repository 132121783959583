import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { LangState, GetterTypes, MutationTypes, ActionTypes } from './types';

const namespaced: boolean = true;

const state: LangState = {
  lang: 'pl'
};

const mutations: MutationTree<LangState> = {
  [MutationTypes.SET_LANG](state, lang: string): void {
    state.lang = lang;
  }
};

const actions: ActionTree<LangState, any> = {
  async [ActionTypes.SELECT_LANG]({ commit }, lang: string): Promise<void> {
    commit(MutationTypes.SET_LANG, lang);
  }
};

const getters: GetterTree<LangState, any> = {
  [GetterTypes.GET_LANG](state): string {
    return state.lang;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
