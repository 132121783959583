import Offer from '@/models/Offer';
import { Dictionary } from '@/types';

export type FetchAction =
  | 'ALL_OFFERS'
  | 'PUBLIC_OFFERS'
  | 'CONCLUDED_OFFERS'
  | 'PUBLIC_OFFERS_FOR_TEACHERS'
  | 'PUBLIC_OFFERS_FOR_MISC'
  | 'DELETED_OFFERS'
  | undefined;

export interface OfferResponseData {
  message: string;
  errors: Dictionary<string[]>;
}

export interface ScheduleOfferPayload {
  offer: Offer;
  date: string;
}

export interface TemplateOfferPayload {
  referenceNumber: string;
  templateOfferUuid: string;
}

export interface OffersState {
  offers: Offer[];
  isFetching: boolean;

  selected: Offer[];
  lastAction: FetchAction;

  lastActionResponseCode?: number;
  lastActionResponseData?: string | OfferResponseData;

  processed: {
    isSearching: boolean;
    query?: string;
    byQuery: Offer[];

    isFiltering: boolean;
    filters?: Dictionary<string[]>;
    byFilters: Offer[];

    isProcessing: boolean;
  };

  paginated: {
    page: number;
    itemsPerPage: number;
    items: Offer[];
  };
}

export enum MutationTypes {
  SET_OFFERS = 'SET_OFFERS',
  SET_PROCESSED_QUERY = 'SET_PROCESSED_QUERY',
  SET_PROCESSED_BY_QUERY = 'SET_PROCESSED_BY_QUERY',
  SET_PROCESSED_FILTERS = 'SET_PROCESSED_FILTERS',
  SET_PROCESSED_BY_FILTERS = 'SET_PROCESSED_BY_FILTERS',
  SET_PAGINATED_PAGE = 'SET_PAGINATED_PAGE',
  SET_PAGINATED_ITEMS_PER_PAGE = 'SET_PAGINATED_ITEMS_PER_PAGE',
  SET_PAGINATED_ITEMS = 'SET_PAGINATED_ITEMS',
  SET_SELECTED_OFFERS = 'SET_SELECTED_OFFERS',
  SET_LAST_ACTION = 'SET_LAST_ACTION',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_IS_SEARCHING = 'SET_IS_SEARCHING',
  SET_IS_FILTERING = 'SET_IS_FILTERING',
  SET_IS_PROCESSING = 'SET_IS_PROCESSING',
  SET_LAST_ACTION_RESPONSE_CODE = 'SET_LAST_ACTION_RESPONSE_CODE',
  SET_LAST_ACTION_RESPONSE_DATA = 'SET_LAST_ACTION_RESPONSE_DATA'
}

export enum ActionTypes {
  DELETE_OFFER = 'DELETE_OFFER',
  CREATE_OFFER = 'CREATE_OFFER',
  CREATE_OFFER_FROM_TEMPLATE = 'CREATE_OFFER_FROM_TEMPLATE',
  PUBLISH_OFFER = 'PUBLISH_OFFER',
  SCHEDULE_OFFER = 'SCHEDULE_OFFER',
  ARCHIVE_OFFER = 'ARCHIVE_OFFER',
  LOCALLY_UPDATE_OFFER = 'LOCALLY_UPDATE_OFFER',
  FETCH_OFFERS = 'FETCH_OFFERS',
  FETCH_OFFER = 'FETCH_OFFER',
  FETCH_PUBLISHED_OFFERS = 'FETCH_PUBLISHED_OFFERS',
  FETCH_CONCLUDED_OFFERS = 'FETCH_CONCLUDED_OFFERS',
  FETCH_DELETED_OFFERS = 'FETCH_DELETED_OFFERS',
  CHANGE_PAGE = 'CHANGE_PAGE',
  CHANGE_ITEMS_PER_PAGE = 'CHANGE_ITEMS_PER_PAGE',
  FILTER = 'FILTERS',
  SEARCH = 'SEARCH',
  CLEAR_OFFERS = 'CLEAR_OFFERS',
  SELECT_OFFERS = 'SELECT_OFFERS',
  CLEAR_SELECTED_OFFERS = 'CLEAR_SELECTED_OFFERS',
  CLEAR_LAST_ACTION_RESPONSE = 'CLEAR_LAST_ACTION_RESPONSE',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  CONCLUDE_OFFER = 'CONCLUDE_OFFER'
}

export enum GetterTypes {
  GET_OFFERS = 'GET_OFFERS',
  GET_PROCESSED_QUERY = 'GET_PROCESSED_QUERY',
  GET_PROCESSED_BY_QUERY = 'GET_PROCESSED_BY_QUERY',
  GET_PROCESSED_FILTERS = 'GET_PROCESSED_FILTERS',
  GET_PROCESSED_BY_FILTERS = 'GET_PROCESSED_BY_FILTERS',
  GET_PAGINATED_PAGE = 'GET_PAGINATED_PAGE',
  GET_PAGINATED_ITEMS_PER_PAGE = 'GET_PAGINATED_ITEMS_PER_PAGE',
  GET_PAGINATED_ITEMS = 'GET_PAGINATED_ITEMS',
  GET_SELECTED_OFFERS = 'GET_SELECTED_OFFERS',
  GET_IS_FETCHING = 'GET_IS_FETCHING',
  GET_IS_SEARCHING = 'GET_IS_SEARCHING',
  GET_IS_FILTERING = 'GET_IS_FILTERING',
  GET_IS_PROCESSING = 'GET_IS_PROCESSING',
  GET_LAST_ACTION_RESPONSE_CODE = 'GET_LAST_ACTION_RESPONSE_CODE',
  GET_LAST_ACTION_RESPONSE_DATA = 'GET_LAST_ACTION_RESPONSE_DATA'
}
