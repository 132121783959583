import { Store } from 'vuex';
import { AxiosInstance } from 'axios';
import DictionariesService from './services/DictionariesService';
import { Hook, Module } from './types';
import dictionaries from './store/dictionaries';

export default class CoreDynamicDictionariesProvider {
  static set store(store: Store<any>) {
    DictionariesService.store = store;
    store.registerModule(['dictionaries'], dictionaries);
  }

  /** @param dictNamespace Namespace for vuex store */
  static set dictNamespace(dictNamespace: string) {
    DictionariesService.dictNamespace = dictNamespace;
  }

  static set hooks(hooks: Hook[]) {
    DictionariesService.hooks = hooks;
  }

  static set api(api: AxiosInstance) {
    DictionariesService.api = api;
  }

  /**
   * @param modules List of module names e.g. [{key: 'offer', apiEndpoint: 'offers'}, {key: 'auth', apiEndpoint:
   *   'auth'}] they will be converted to apiUrl/apiEndpoint/dictionaries
   */
  static set modules(modules: Module[]) {
    DictionariesService.modules = modules;
  }
}
