import { AxiosInstance } from 'axios';
import SessionWatcherService from './SessionWatcherService';

const sessionWatcherInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (response) => {
      SessionWatcherService.refreshCounter();
      return Promise.resolve(response);
    },
    (error) => Promise.reject(error)
  );
};

export default sessionWatcherInterceptor;
