import { AxiosInstance } from 'axios';
import TokenService from '../services/TokenService';

const tokenInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = TokenService.getAccessTokenFromStorage();

      if (accessToken && config && config.headers) {
        // @ts-ignore
        config.headers.common.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

export default tokenInterceptor;
