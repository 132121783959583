import pl from './lang/pl';
import en from './lang/en';

export default class CoreInputValidatorsProvider {
  public static i18n: any;

  public static mergeLocales(i18n: any): void {
    this.i18n = i18n;

    i18n.mergeLocaleMessage('pl', pl);
    i18n.mergeLocaleMessage('en', en);
  }
}