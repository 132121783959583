const pl = {
  coreInputValidator: {
    minRuleString:
      '{label} musi mieć przynajmniej jeden znak! | {label} musi mieć przynajmniej {n} znaki!| {label} musi mieć przynajmniej {n} znaków!',
    maxRuleString:
      '{label} nie może byc dłuższe niż jeden znak! | {label} nie może byc dłuższe niż {n} znaki | {label} nie może byc dłuższe niż {n} znaków!',
    minRuleNumber: '{label} musi wynosić przynajmniej {minLength}!',
    maxRuleNumber: '{label} nie może być większe niż {maxLength}!',
    isNumberRule: '{label} musi być liczbą!',
    required: '{label} jest wymagane!',
    input: 'Pole',
    date: 'Data',
    minDate: '{label} nie może być mniejsza niż {date}!',
    maxDate: '{label} nie może być większa niż {date}!'
  }
};

export default pl;
