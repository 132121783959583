import { IVueI18n } from 'vue-i18n';
import { AxiosInstance } from 'axios';
import VueRouter from 'vue-router';
import pl from './lang/pl';
import en from './lang/en';
import sessionWatcherInterceptor from './SessionWatcherInterceptor';
import SessionWatcherService from './SessionWatcherService';

export default class SessionWatcherProvider {
  public static attachInterceptors(api: AxiosInstance): void {
    SessionWatcherService.api = api;
    sessionWatcherInterceptor(api);
  }

  public static mergeLocales(i18n: IVueI18n): void {
    SessionWatcherService.i18n = i18n;
    i18n.mergeLocaleMessage('pl', pl);
    i18n.mergeLocaleMessage('en', en);
  }

  public static attachRouter(router: VueRouter): void {
    SessionWatcherService.router = router;
  }

  /**
   * @param redirectRouteName Name for route to redirect after invalidating user session
   */
  static set redirectRouteName(redirectRouteName: string) {
    SessionWatcherService.redirectRouteName = redirectRouteName;
  }

  /**
   * @param afkTime Time in ms to invalidate user session
   */
  static set afkTime(afkTime: number) {
    SessionWatcherService.afkTime = afkTime;
  }
}
