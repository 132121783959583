export interface LangState {
  lang: string;
}

export enum MutationTypes {
  SET_LANG = 'SET_LANG'
}

export enum ActionTypes {
  SELECT_LANG = 'SELECT_LANG'
}

export enum GetterTypes {
  GET_LANG = 'GET_LANG'
}
