import JwtDecode from 'jwt-decode';
import { UserBaseModel, TokensModel, TokenDecodedModel } from '@/modules/core/core-types';
import { LoggingHelper, LogLevel } from '@/modules/core/core-helpers';

export default class TokenService {
  private static readonly ACCESS_TOKEN_ID: string = 'ACCESS_TOKEN_ID';

  private static readonly REFRESH_TOKEN_ID: string = 'REFRESH_TOKEN_ID';

  private static readonly SSO_ID: string = 'SSO_ID';

  public static getAccessTokenFromStorage(): string | null {
    LoggingHelper.log('Getting access token from storage', LogLevel.DEBUG, [
      'core-auth',
      'TokenService',
      'getAccessTokenFromStorage'
    ]);
    return localStorage.getItem(this.ACCESS_TOKEN_ID);
  }

  public static getRefreshTokenFromStorage(): string | null {
    LoggingHelper.log('Getting refresh token from storage', LogLevel.DEBUG, [
      'core-auth',
      'TokenService',
      'getRefreshTokenFromStorage'
    ]);
    return localStorage.getItem(this.REFRESH_TOKEN_ID);
  }

  public static saveTokensToLocalStorage(tokens: TokensModel): void {
    LoggingHelper.log('Attempting to save tokens.', LogLevel.DEBUG, [
      'core-auth',
      'TokenService',
      'saveTokensToLocalStorage'
    ]);

    if (tokens && tokens.accessToken && tokens.refreshToken) {
      localStorage.setItem(this.ACCESS_TOKEN_ID, tokens.accessToken);
      localStorage.setItem(this.REFRESH_TOKEN_ID, tokens.refreshToken);

      LoggingHelper.log('Tokens saved.', LogLevel.DEBUG, ['core-auth', 'TokenService', 'saveTokensToLocalStorage']);
    } else {
      // TODO(jakwoj1150) custom error class
      LoggingHelper.log('Tokens could not be saved because they are empty.', LogLevel.CRITICAL, [
        'core-auth',
        'TokenService',
        'saveTokensToLocalStorage'
      ]);
      throw new Error('Tokens could not be saved because they are empty.');
    }
  }

  public static getTokensFromLocalStorage(): TokensModel | null {
    LoggingHelper.log('Getting tokens.', LogLevel.DEBUG, ['core-auth', 'TokenService', 'getTokensFromLocalStorage']);

    const accessToken = this.getAccessTokenFromStorage();
    const refreshToken = this.getRefreshTokenFromStorage();

    if (!accessToken || !refreshToken) {
      LoggingHelper.log('No tokens in local storage!', LogLevel.DEBUG, [
        'core-auth',
        'TokenService',
        'getTokensFromLocalStorage'
      ]);
      return null;
    }

    return { accessToken, refreshToken };
  }

  public static removeTokensFromLocalStorage(): void {
    localStorage.removeItem(this.ACCESS_TOKEN_ID);
    localStorage.removeItem(this.REFRESH_TOKEN_ID);

    LoggingHelper.log('Removed tokens from local storage.', LogLevel.DEBUG, [
      'core-auth',
      'TokenService',
      'removeTokensFromLocalStorage'
    ]);
  }

  public static isAccessTokenValid(): boolean {
    const accessToken = this.getAccessTokenFromStorage();
    if (accessToken) {
      return this.isTokenValid(accessToken);
    }

    return false;
  }

  public static isRefreshTokenValid(): boolean {
    const accessToken = this.getRefreshTokenFromStorage();
    if (accessToken) {
      return this.isTokenValid(accessToken);
    }

    return false;
  }

  public static getUserFromToken(): UserBaseModel | null {
    const token = this.getAccessTokenFromStorage();
    if (token) {
      const decodedToken: TokenDecodedModel = JwtDecode(token);
      return {
        username: decodedToken.username,
        uuid: decodedToken.uuid,
        email: decodedToken.email
      };
    }

    return null;
  }

  private static isTokenValid(token: string): boolean {
    const decodedToken: TokenDecodedModel = JwtDecode(token);
    const expiredDate: number = decodedToken.exp;
    const now: number = Math.floor(Date.now() / 1000);

    return expiredDate > now;
  }

  public static wasSsoLogin(): boolean {
    const sso: string | null = localStorage.getItem(this.SSO_ID);

    return !!(sso && sso === 'true');
  }

  public static clearSsoLogin(): void {
    localStorage.removeItem(this.SSO_ID);
  }

  public static setSsoLogin(): void {
    localStorage.setItem(this.SSO_ID, 'true');
  }
}
