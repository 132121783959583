import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { isArray } from 'lodash';
import { AuthGuard, AuthGuardOptions, AuthService } from '@/modules/core/core-auth';
// import SiteUnderConstructionView from '@/views/Globals/SiteUnderConstructionView.vue';
import store from '@/store';
import { ActionTypes as RouterActionTypes } from '@/store/router/types';
import RouterModuleInterface from '@/router/RouterModuleInterface';
import AdminRoutes from '@/router/admin';
import OfferRoutes from '@/router/offers';
import SiteRoutes from '@/router/site';
import GlobalRoutes from '@/router/global';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';

Vue.use(VueRouter);

const routerNamespace = 'router';

const authGuardOptions: AuthGuardOptions = {
  ssoPostAuthRouteName: GlobalRoutesNames.LOGIN,
  unauthorizedRouteName: GlobalRoutesNames.E401
};

const authGuard: AuthGuard = new AuthGuard(authGuardOptions);

const routesModules: RouterModuleInterface[] = [
  new GlobalRoutes(),
  new SiteRoutes(),
  new OfferRoutes(),
  new AdminRoutes()
];

let routes: RouteConfig[] = [];

routesModules.forEach((routeModule: RouterModuleInterface) => {
  const module = routeModule.module(authGuard);

  if (isArray(module)) {
    routes = routes.concat(module);
  } else {
    routes.push(module);
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const user = await AuthService.getUserFromStore();

  // Prohibits user from using site if it has no roles
  if (
    user &&
    user.roles?.length === 0 &&
    from.name !== GlobalRoutesNames.LOGOUT &&
    to.name !== GlobalRoutesNames.LOGOUT
  ) {
    next({ name: GlobalRoutesNames.LOGOUT });
    return;
  }

  if (
    process.env.VUE_APP_SITE_UNDER_CONSTRUCTION === 'true' &&
    //    !localStorage.getItem(SiteUnderConstructionView.ackKey) &&
    !localStorage.getItem('SITE_UNDER_CONSTRUCTION_ACK') &&
    to.name !== GlobalRoutesNames.SITE_UNDER_CONSTRUCTION
  ) {
    next({ name: GlobalRoutesNames.SITE_UNDER_CONSTRUCTION });
    return;
  }

  await store.dispatch(`${routerNamespace}/${RouterActionTypes.PUSH}`, { from, to });
  next();
});

export default router;
