import axios, { AxiosError } from 'axios';

export enum LogLevel {
  DEBUG,
  INFO,
  ERROR,
  CRITICAL
}

type Path = string[] | null;

export default class LoggingHelper {
  public static logLevel: LogLevel = LogLevel.CRITICAL;

  public static log(message: any, logLevel: LogLevel = LogLevel.DEBUG, path: Path = null): void {
    if (logLevel >= this.logLevel) {
      this.processLog(message, logLevel, path);
    }
  }

  private static processLog(message: any, logLevel: LogLevel, path: Path = null): void {
    const logLevelName = LogLevel[logLevel];
    const pathParsed = path ? path.join(':') : '';
    const prependString = `${logLevelName}:${pathParsed}:`;

    if (logLevel === LogLevel.DEBUG) {
      if (typeof message === 'string' || typeof message === 'number') {
        console.log(`${prependString} ${message}`);
        return;
      }

      console.log(`${prependString}`, message);
    }

    if (logLevel === LogLevel.INFO) {
      if (typeof message === 'string' || typeof message === 'number') {
        console.info(`${prependString}: ${message}`);
        return;
      }

      console.info(`${prependString}`, message);
    }

    if (logLevel === LogLevel.ERROR || logLevel === LogLevel.CRITICAL) {
      if (typeof message === 'string' || typeof message === 'number') {
        console.error(`${prependString} ${message}`);
        return;
      }

      if (axios.isAxiosError(message)) {
        console.error(`${prependString}`, (message as AxiosError).response);
        return;
      }

      console.error(`${prependString}`, message);
    }
  }
}
