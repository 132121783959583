const pl = {
  pwrFileUploader: {
    labelIdle: 'Zaznacz i upuść pliki lub <span class="filepond--label-action"> Przeglądaj </span>',
    labelInvalidField: 'Pole zawiera niewłaściwe pliki',
    labelFileWaitingForSize: 'Oczekuję na rozmiar',
    labelFileSizeNotAvailable: 'Rozmiar niedostępny',
    labelFileLoading: 'Ładowanie',
    labelFileLoadError: 'Błąd podczas ładowania',
    labelFileProcessing: 'Wgrywanie',
    labelFileProcessingComplete: 'Wgrywanie zakończone',
    labelFileProcessingAborted: 'Wgrywanie anulowane',
    labelFileProcessingError: 'Błąd podczas wgrywania',
    labelFileProcessingRevertError: 'Błąd podczas cofania',
    labelFileRemoveError: 'Błąd podczas usuwania',
    labelTapToCancel: 'kliknij aby anulować',
    labelTapToRetry: 'kliknij aby powtórzyć',
    labelTapToUndo: 'kliknij aby cofnąć',
    labelButtonRemoveItem: 'Usuń',
    labelButtonAbortItemLoad: 'Anuluj',
    labelButtonRetryItemLoad: 'Ponów',
    labelButtonAbortItemProcessing: 'Anuluj',
    labelButtonUndoItemProcessing: 'Cofnij',
    labelButtonRetryItemProcessing: 'Ponów',
    labelButtonProcessItem: 'Wgraj',
    plugins: {
      validateSize: {
        labelMaxFileSizeExceeded: 'Plik jest za duży!',
        labelMaxFileSize: 'Maksymalny rozmiar pliku to {filesize}',
        labelMaxTotalFileSizeExceeded: 'Suma rozmiarów wszystkich plików jest za duża!',
        labelMaxTotalFileSize: 'Maksymalny rozmiar całkowity to {filesize}'
      },
      validateType: {
        labelFileTypeNotAllowed: 'Niewłaściwy typ pliku',
        fileValidateTypeLabelExpectedTypes: 'Oczekiwane {allButLastType} lub {lastType}'
      }
    }
  }
};

export default pl;
