import { AxiosInstance } from 'axios';
import { Store } from 'vuex';
import {
  ActionTypes as DictActionTypes,
  DictionaryKey,
  DictionaryOfDictionaries,
  DictionaryOfRawDictionaries,
  GetterTypes as DictGetterTypes,
  Hook,
  Module,
  ParsedDictionary,
  RawDictionary
} from '../types';

export default class DictionariesService {
  public static hooks: Hook[];
  public static dictNamespace: string = 'dictionaries';
  public static store: Store<any>;
  public static api: AxiosInstance;
  public static modules: Module[];

  public static async fetchDictionaries(): Promise<void> {
    await this.store.dispatch(`${this.dictNamespace}/${DictActionTypes.CLEAR_DICTIONARIES}`);

    if (!this.hooks || this.hooks?.length === 0) {
      this.hooks = [];

      if (!this.modules || this.modules?.length === 0) {
        return;
      }

      this.modules.forEach((module: Module) => {
        const callback = async () => {
          const response = await DictionariesService.api.get(`${module.apiEndpoint}/dictionaries`);
          // const { data }: { data: DictionaryOfRawDictionaries } = response.data;
          // return data;

          return response.data;
        };

        DictionariesService.hooks.push({
          key: module.key,
          callback
        });
      });
    }

    await this.store.dispatch(`${this.dictNamespace}/${DictActionTypes.FETCH_DICTIONARIES}`);
  }

  public static parseRawDict(rawDict: RawDictionary): ParsedDictionary {
    const parsedDict: ParsedDictionary = {
      name: rawDict.name,
      labels: {},
      constants: {}
    };
    rawDict.labels.forEach((item: DictionaryKey) => {
      parsedDict.labels[item.value.toString()] = item.label;
      parsedDict.constants[item.value.toString()] = item.key;
    });
    return parsedDict;
  }

  public static parseArrayOfRawDicts(rawDicts: DictionaryOfRawDictionaries): DictionaryOfDictionaries {
    const parsedDicts: DictionaryOfDictionaries = {};

    Object.keys(rawDicts).forEach((key: string) => {
      parsedDicts[key] = this.parseRawDict(rawDicts[key]);
    });

    return parsedDicts;
  }

  private static resolvePathForLabelOrConstant(
    query: string | string[],
    type: 'labels' | 'constants' = 'labels',
    auxSource: DictionaryOfDictionaries | null = null,
    isReversed: boolean = false
  ): string | null {
    const path: string[] = Array.isArray(query) ? query : query.split('.');

    if (!(path.length === 3)) {
      return null;
    }

    let dicts = null;

    if (auxSource) {
      dicts = auxSource;
    } else {
      dicts = this.store.getters[`${this.dictNamespace}/${DictGetterTypes.GET_DICTIONARIES}`];
    }

    if (path[0] in dicts) {
      if (path[1] in dicts[path[0]]) {
        if (isReversed) {
          const key = path[2];
          let dict: any;

          if (type === 'labels' && path[2] in dicts[path[0]][path[1]].labels) {
            dict = dicts[path[0]][path[1]].labels;
          } else if (path[2] in dicts[path[0]][path[1]].constants) {
            dict = dicts[path[0]][path[1]].constants;
          }

          const dictKeys = Object.keys(dict);

          // eslint-disable-next-line consistent-return
          dictKeys.forEach((labelKey: string) => {
            const value = dict[labelKey];

            // eslint-disable-next-line eqeqeq
            if (value == key) {
              return labelKey;
            }
          });

          return null;
        }
          if (type === 'labels' && path[2] in dicts[path[0]][path[1]].labels) {
            return dicts[path[0]][path[1]].labels[path[2]];
          }

          if (path[2] in dicts[path[0]][path[1]].constants) {
            return dicts[path[0]][path[1]].constants[path[2]];
          }

      }
    }

    return null;
  }

  public static getLabel(key: string, auxSource: DictionaryOfDictionaries | null = null): string | null {
    return this.resolvePathForLabelOrConstant(key, 'labels', auxSource);
  }

  public static getConstant(key: string, auxSource: DictionaryOfDictionaries | null = null): string | null {
    return this.resolvePathForLabelOrConstant(key, 'constants', auxSource);
  }

  public static getConstantValue(
    key: string,
    auxSource: DictionaryOfDictionaries | null = null
  ): string | number | null {
    const val = this.resolvePathForLabelOrConstant(key, 'constants', auxSource, true);

    if (val && !Number.isNaN(+val) && !Number.isNaN(parseFloat(val))) {
      return +val;
    }

    return val;
  }

  public static resolvePathForLabelsOrConstants(
    query: string | string[],
    type: 'labels' | 'constants' = 'labels',
    auxSource: DictionaryOfDictionaries | null = null
  ): string[] | null {
    const path: string[] = Array.isArray(query) ? query : query.split('.');

    if (!(path.length >= 2 && path.length <= 3)) {
      return null;
    }

    let dicts = null;

    if (auxSource) {
      dicts = auxSource;
    } else {
      dicts = this.store.getters[`${this.dictNamespace}/${DictGetterTypes.GET_DICTIONARIES}`];
    }

    if (path[0] in dicts) {
      if (path[1] in dicts[path[0]]) {
        if (type === 'labels') {
          return dicts[path[0]][path[1]].labels;
        }

        return dicts[path[0]][path[1]].constants;
      }
    }

    return null;
  }

  public static getLabels(query: string, auxSource: DictionaryOfDictionaries | null = null): string[] | null {
    return this.resolvePathForLabelsOrConstants(query, 'labels', auxSource);
  }

  public static getConstants(query: string, auxSource: DictionaryOfDictionaries | null = null): string[] | null {
    return this.resolvePathForLabelsOrConstants(query, 'constants', auxSource);
  }
}
