import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isArray } from 'lodash';
import moment from 'moment';
import { AxiosError } from 'axios';
import { ErrorHelper } from '@/modules/core/core-helpers';
import Offer, { OfferStates } from '@/models/Offer';
import OffersStoreService from '@/store/offers/OffersStoreService';
import { Dictionary } from '@/types';
import UnauthorizedException from '@/exceptions/auth/UnauthorizedException';
import NotFoundException from '@/exceptions/http/NotFoundException';
import AlreadyExistException from '@/exceptions/entities/AlreadyExistException';
import {
  ActionTypes,
  FetchAction,
  GetterTypes,
  MutationTypes,
  OfferResponseData,
  OffersState,
  ScheduleOfferPayload,
  TemplateOfferPayload
} from './types';
import OffersService from '../../services/offers/OffersService';

const namespaced: boolean = true;

// @ts-ignore
const state: OffersState = {
  offers: [],
  isFetching: false,

  selected: [],
  lastAction: undefined,

  lastActionResponseCode: undefined,
  lastActionResponseData: undefined,

  processed: {
    isSearching: false,
    query: undefined,
    byQuery: [],

    isFiltering: false,
    filters: undefined,
    byFilters: [],

    isProcessing: false
  },

  paginated: {
    page: 1,
    itemsPerPage: 10,
    items: []
  }
};

const mutations: MutationTree<OffersState> = {
  [MutationTypes.SET_OFFERS](state, payload: Offer[]): void {
    state.offers = payload;
    state.processed.byQuery = payload;
    state.processed.byFilters = payload;
  },
  [MutationTypes.SET_SELECTED_OFFERS](state, payload: Offer[]): void {
    state.selected = payload;
  },
  [MutationTypes.SET_PROCESSED_QUERY](state, payload: string): void {
    state.processed.query = payload;
  },
  [MutationTypes.SET_PROCESSED_BY_QUERY](state, payload: Offer[]): void {
    state.processed.byQuery = payload;
  },
  [MutationTypes.SET_PROCESSED_FILTERS](state, payload: Dictionary<string[]> | undefined): void {
    state.processed.filters = payload;
  },
  [MutationTypes.SET_PROCESSED_BY_FILTERS](state, payload: Offer[]): void {
    state.processed.byFilters = payload;
  },
  [MutationTypes.SET_PAGINATED_PAGE](state, payload: number): void {
    state.paginated.page = payload;
  },
  [MutationTypes.SET_PAGINATED_ITEMS_PER_PAGE](state, payload: number): void {
    state.paginated.itemsPerPage = payload;
  },
  [MutationTypes.SET_PAGINATED_ITEMS](state, payload: Offer[]): void {
    state.paginated.items = payload;
  },
  [MutationTypes.SET_LAST_ACTION](state, payload: FetchAction): void {
    state.lastAction = payload;
  },
  [MutationTypes.SET_IS_FETCHING](state, payload: boolean): void {
    state.isFetching = payload;
  },
  [MutationTypes.SET_IS_SEARCHING](state, payload: boolean): void {
    state.processed.isSearching = payload;
  },
  [MutationTypes.SET_IS_FILTERING](state, payload: boolean): void {
    state.processed.isFiltering = payload;
  },
  [MutationTypes.SET_IS_PROCESSING](state, payload: boolean): void {
    state.processed.isProcessing = payload;
  },
  [MutationTypes.SET_LAST_ACTION_RESPONSE_CODE](state, payload: number | undefined): void {
    state.lastActionResponseCode = payload;
  },
  [MutationTypes.SET_LAST_ACTION_RESPONSE_DATA](
    state,
    payload: string | OfferResponseData | undefined
  ): void {
    state.lastActionResponseData = payload;
  }
};

const actions: ActionTree<OffersState, any> = {
  async [ActionTypes.DELETE_OFFER]({ commit, dispatch }, payload: Offer): Promise<boolean> {
    try {
      await OffersService.delete(payload);

      payload.state = -1;

      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, payload);

      return true;
    } catch (error: unknown) {
      const parsedError = ErrorHelper.getAxiosError(error);

      if (parsedError) {
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, parsedError.status);
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, parsedError.data);
      }

      return false;
    }
  },

  async [ActionTypes.SCHEDULE_OFFER](
    { dispatch, commit },
    payload: ScheduleOfferPayload
  ): Promise<boolean> {
    try {
      const returnedOffer = await OffersService.schedule(payload.offer, payload.date);

      commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, 200);
      commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, undefined);

      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, returnedOffer);

      return true;
    } catch (error: unknown) {
      const parsedError = ErrorHelper.getAxiosError(error);

      if (parsedError) {
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, parsedError.status);
        commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, parsedError.data);
      }

      return false;
    }
  },

  [ActionTypes.LOCALLY_UPDATE_OFFER]({ state, commit }, payload: Offer): void {
    const { offers } = state;
    let newOffers: Offer[] = [];
    if (payload.state !== -1) {
      newOffers = offers.map((offer: Offer) => {
        if (offer.uuid === payload.uuid) {
          return payload;
        }

        return offer;
      });
    } else {
      newOffers = offers.filter((offer: Offer) => offer.uuid !== payload.uuid);
    }

    commit(MutationTypes.SET_OFFERS, newOffers);
  },

  async [ActionTypes.PUBLISH_OFFER]({ commit, dispatch }, payload: Offer): Promise<boolean> {
    const result = await OffersStoreService.changeOfferState(payload, OfferStates.PUBLIC, commit);

    if (result) {
      payload.state = OfferStates.PUBLIC;
      payload.publicationDate = moment().format('YYYY-MM-DD');

      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, payload);
    }

    return result;
  },

  async [ActionTypes.CONCLUDE_OFFER]({ commit, dispatch }, payload: Offer): Promise<boolean> {
    const result = await OffersStoreService.changeOfferState(
      payload,
      OfferStates.CONCLUDED,
      commit
    );

    if (result) {
      payload.state = OfferStates.CONCLUDED;
      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, payload);
    }

    return result;
  },

  async [ActionTypes.ARCHIVE_OFFER]({ commit, dispatch }, payload: Offer): Promise<boolean> {
    const result = await OffersStoreService.changeOfferState(payload, OfferStates.ARCHIVE, commit);

    if (result) {
      payload.state = OfferStates.ARCHIVE;
      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, payload);
    }

    return result;
  },

  async [ActionTypes.FETCH_OFFERS](
    { commit, dispatch, state },
    force: boolean = false
  ): Promise<void> {
    await OffersStoreService.fetchAction(
      state.lastAction,
      'ALL_OFFERS',
      dispatch,
      commit,
      state,
      null,
      force
    );
  },

  async [ActionTypes.FETCH_PUBLISHED_OFFERS](
    { commit, dispatch, state },
    typesScope?: number[]
  ): Promise<void> {
    await OffersStoreService.fetchAction(
      state.lastAction,
      'PUBLIC_OFFERS',
      dispatch,
      commit,
      state,
      typesScope,
      !!typesScope
    );
  },

  async [ActionTypes.FETCH_CONCLUDED_OFFERS]({ commit, dispatch, state }): Promise<void> {
    await OffersStoreService.fetchAction(
      state.lastAction,
      'CONCLUDED_OFFERS',
      dispatch,
      commit,
      state
    );
  },

  async [ActionTypes.FETCH_DELETED_OFFERS]({ commit, dispatch, state }): Promise<void> {
    await OffersStoreService.fetchAction(
      state.lastAction,
      'DELETED_OFFERS',
      dispatch,
      commit,
      state
    );
  },

  [ActionTypes.SELECT_OFFERS]({ commit }, payload: Offer | Offer[]): void {
    if (isArray(payload)) {
      commit(MutationTypes.SET_SELECTED_OFFERS, payload);
    } else {
      commit(MutationTypes.SET_SELECTED_OFFERS, [payload]);
    }
  },

  [ActionTypes.CLEAR_OFFERS]({ commit }): void {
    commit(MutationTypes.SET_OFFERS, []);
    commit(MutationTypes.SET_SELECTED_OFFERS, []);
    commit(MutationTypes.SET_PROCESSED_BY_QUERY, []);
    commit(MutationTypes.SET_PROCESSED_BY_FILTERS, []);
    commit(MutationTypes.SET_PAGINATED_ITEMS, []);
    commit(MutationTypes.SET_PAGINATED_PAGE, 1);
    commit(MutationTypes.SET_LAST_ACTION, undefined);
  },

  [ActionTypes.CHANGE_PAGE]({ commit, state }, payload: number): void {
    commit(MutationTypes.SET_PAGINATED_PAGE, payload);

    const source =
      state.processed.byFilters.length > state.processed.byQuery.length
        ? state.processed.byQuery
        : state.processed.byFilters;

    const result = OffersStoreService.paginate(
      source,
      state.paginated.page,
      state.paginated.itemsPerPage
    );

    commit(MutationTypes.SET_PAGINATED_ITEMS, result);
  },

  [ActionTypes.CHANGE_ITEMS_PER_PAGE]({ commit, state }, payload: number): void {
    commit(MutationTypes.SET_PAGINATED_ITEMS_PER_PAGE, payload);
    commit(
      MutationTypes.SET_PAGINATED_ITEMS,
      OffersStoreService.paginate(
        state.processed.byFilters,
        state.paginated.page,
        state.paginated.itemsPerPage
      )
    );
  },

  [ActionTypes.FILTER](
    { commit, state, dispatch },
    payload: Dictionary<number[]> | undefined
  ): void {
    commit(MutationTypes.SET_IS_PROCESSING, true);
    commit(MutationTypes.SET_IS_FILTERING, true);
    commit(MutationTypes.SET_PROCESSED_FILTERS, payload);

    const offersSource = state.processed.byQuery ? state.processed.byQuery : state.offers;

    if (payload && Object.keys(payload).length) {
      commit(
        MutationTypes.SET_PROCESSED_BY_FILTERS,
        OffersStoreService.processByFilters(offersSource, payload)
      );
    } else {
      commit(MutationTypes.SET_PROCESSED_BY_FILTERS, offersSource);
    }

    commit(MutationTypes.SET_IS_FILTERING, false);
    dispatch(ActionTypes.CHANGE_PAGE, 1);
    commit(MutationTypes.SET_IS_PROCESSING, false);
  },

  [ActionTypes.SEARCH]({ commit, state, dispatch }, payload: string | undefined): void {
    commit(MutationTypes.SET_IS_PROCESSING, true);
    commit(MutationTypes.SET_IS_SEARCHING, true);
    commit(MutationTypes.SET_PROCESSED_QUERY, payload);

    if (payload && payload.length) {
      commit(
        MutationTypes.SET_PROCESSED_BY_QUERY,
        OffersStoreService.processByQuery(state.offers, payload)
      );
    } else {
      commit(MutationTypes.SET_PROCESSED_BY_QUERY, state.offers);
    }

    commit(MutationTypes.SET_IS_SEARCHING, false);
    dispatch(ActionTypes.FILTER, []);
    commit(MutationTypes.SET_IS_PROCESSING, false);
  },
  [ActionTypes.CLEAR_SEARCH]({ commit, state, dispatch }): void {
    // Clear search if it really was used, to avoid waisting time on filter action.
    if (state.processed.query) {
      commit(MutationTypes.SET_PROCESSED_BY_QUERY, state.offers);
      commit(MutationTypes.SET_PROCESSED_QUERY, undefined);
      dispatch(ActionTypes.FILTER, []);
    }
  },
  [ActionTypes.CLEAR_SELECTED_OFFERS]({ commit }): void {
    commit(MutationTypes.SET_SELECTED_OFFERS, []);
  },
  [ActionTypes.CLEAR_LAST_ACTION_RESPONSE]({ commit }): void {
    commit(MutationTypes.SET_LAST_ACTION_RESPONSE_CODE, undefined);
    commit(MutationTypes.SET_LAST_ACTION_RESPONSE_DATA, undefined);
  },
  async [ActionTypes.FETCH_OFFER](
    { dispatch, state },
    offerUuid: string
  ): Promise<Offer | undefined> {
    const filteredOffers = state.offers.filter((offer: Offer) => offer.uuid === offerUuid);

    // Return 'cached' offer
    if (filteredOffers.length) {
      return filteredOffers[0];
    }

    // Download offer entity
    try {
      const offer = await OffersService.get(offerUuid);
      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, offer);
      return offer;
    } catch (e: unknown) {
      const axiosError = ErrorHelper.getAxiosError(e);

      if (axiosError && (axiosError.status === 401 || axiosError.status === 403)) {
        throw new UnauthorizedException();
      }

      throw new NotFoundException();
    }
  },

  async [ActionTypes.CREATE_OFFER](
    { state, dispatch },
    newOffer: Offer
  ): Promise<Offer | undefined> {
    const filteredOffers = state.offers.filter(
      (offer: Offer) => offer.referenceNumber === newOffer.referenceNumber
    );

    if (filteredOffers.length) {
      throw new AlreadyExistException();
    }

    try {
      const storedOffer = await OffersService.store(newOffer);
      if (storedOffer) {
        dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, storedOffer);
      }
      return storedOffer;
    } catch (e: unknown) {
      const axiosError = ErrorHelper.getAxiosError(e);

      if (axiosError && (axiosError.status === 401 || axiosError.status === 403)) {
        throw new UnauthorizedException();
      }
      if (axiosError) {
        const error = new Error() as AxiosError;
        error.response = axiosError;

        throw error;
      }
      throw new Error();
    }
  },

  async [ActionTypes.CREATE_OFFER_FROM_TEMPLATE](
    { state, dispatch },
    payload: TemplateOfferPayload
  ): Promise<Offer | undefined> {
    const filteredOffers = state.offers.filter(
      (offer: Offer) => offer.referenceNumber === payload.referenceNumber
    );

    if (filteredOffers.length) {
      throw new AlreadyExistException();
    }

    // Store offer entity
    try {
      const storedOffer = await OffersService.storeFromTemplate(
        payload.referenceNumber,
        payload.templateOfferUuid
      );
      dispatch(ActionTypes.LOCALLY_UPDATE_OFFER, storedOffer);
      return storedOffer;
    } catch (e: unknown) {
      const axiosError = ErrorHelper.getAxiosError(e);

      if (axiosError && (axiosError.status === 401 || axiosError.status === 403)) {
        throw new UnauthorizedException();
      }

      if (axiosError) {
        const error = new Error() as AxiosError;
        error.response = axiosError;

        throw error;
      }

      throw new Error();
    }
  }
};

const getters: GetterTree<OffersState, any> = {
  [GetterTypes.GET_OFFERS](state): Offer[] {
    return state.offers;
  },
  [GetterTypes.GET_PROCESSED_QUERY](state): string | undefined {
    return state.processed.query;
  },
  [GetterTypes.GET_PROCESSED_BY_QUERY](state): Offer[] {
    return state.processed.byQuery;
  },
  [GetterTypes.GET_PROCESSED_FILTERS](state): Dictionary<string[]> | undefined {
    return state.processed.filters;
  },
  [GetterTypes.GET_PROCESSED_BY_FILTERS](state): Offer[] {
    return state.processed.byFilters;
  },
  [GetterTypes.GET_PAGINATED_PAGE](state): number {
    return state.paginated.page;
  },
  [GetterTypes.GET_PAGINATED_ITEMS_PER_PAGE](state): number {
    return state.paginated.itemsPerPage;
  },
  [GetterTypes.GET_PAGINATED_ITEMS](state): Offer[] {
    return state.paginated.items;
  },
  [GetterTypes.GET_SELECTED_OFFERS](state): Offer[] {
    return state.selected;
  },
  [GetterTypes.GET_IS_FETCHING](state): boolean {
    return state.isFetching;
  },
  [GetterTypes.GET_IS_SEARCHING](state): boolean {
    return state.processed.isSearching;
  },
  [GetterTypes.GET_IS_FILTERING](state): boolean {
    return state.processed.isFiltering;
  },
  [GetterTypes.GET_IS_PROCESSING](state): boolean {
    return state.processed.isProcessing;
  },
  [GetterTypes.GET_LAST_ACTION_RESPONSE_CODE](state): number | undefined {
    return state.lastActionResponseCode;
  },
  [GetterTypes.GET_LAST_ACTION_RESPONSE_DATA](state): string | OfferResponseData | undefined {
    return state.lastActionResponseData;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
