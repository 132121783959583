export enum PwrSnackbarTypes {
  DEFAULT = '',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export enum PwrSnackbarIcon {
  NONE = 'none',
  SMILE = 'smileIcon',
  FROWN = 'frownIcon',
  INFO = 'infoIcon'
}
