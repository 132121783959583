const en = {
  appName: 'Job offers',
  views: {
    adminPanel: {
      jobOffers: {
        form: {
          header: 'Basics'
        },
        title: 'Job Offers Administration',
        dataTable: {
          headers: {
            operations: 'Operations'
          }
        },
        wyswig: {
          buttons: {
            default: 'Default'
          }
        },
        snackbar: {
          stateValidation: {
            message: 'Some parts of the offer are invalid! Click "Edit offer" to see more.',
            message2: 'Some parts of the offer are invalid!',
            buttons: {
              close: 'Close',
              edit: 'Edit offer'
            }
          },
          messages: {
            success: {
              published: 'Offer has been published!',
              scheduledPublication: 'Offer has been scheduled for publishing!',
              deleted: 'Offer has been deleted!',
              archived: 'Offer has been archived!'
            },
            errors: {
              canNotBeArchive: 'This offer cannot be archived!',
              notFound: 'Offer not found!'
            }
          }
        },
        components: {
          newOfferDialog: {
            title: 'New job offer',
            titleFromTemplate: 'New job offer from template'
          },
          offerScheduleDialog: {
            title: 'Select publication date',
            buttons: {
              accept: 'Schedule'
            }
          },
          yesNoDialog: {
            cannotUndone: 'This action cannot be undone!',
            actions: {
              publish: {
                title: 'Publication confirmation',
                message: 'Are you sure you want to publish the offer?'
              },
              delete: {
                title: 'Deletion confirmation',
                message: 'Are you sure you want to delete the offer??'
              },
              archive: {
                title: 'Archivization confirmation',
                message: 'Are you sure you want to archive the offer?'
              }
            }
          },
          competitionConclusionDialog: {
            title: 'Competition conclusion',
            buttons: {
              yes: 'Conclude',
              yes2: 'Update'
            },
            errors: {
              offerRef: 'Cannot conclude the offer. Missing reference!',
              offer: 'Could not conclude the offer.',
              fileAttach: 'Nie udało się dodać pliku do oferty!',
              fileUpload: 'Nie udało się poprawnie odebrać pliku!'
            },
            success: {
              offer: 'Offer concluded!',
              offer2: 'Offer conclusion updated!'
            }
          },
          actionItemsGroup: {
            tooltips: {
              publish: 'Publish',
              scheduledPublication: 'Schedule publication',
              archive: 'Archive',
              conclude: 'Conclude',
              concludeAgain: 'Conclude again',
              edit: 'Edit',
              template: 'Use as template',
              preview: 'Preview',
              delete: 'Delete'
            }
          },
          eventsLog: {
            title: 'Events log',
            close: 'Close',
            headers: {
              date: 'Date',
              user: 'User',
              event: 'Event'
            },
            'description': {
              offer: {
                created: 'Created',
                updated: 'Updated',
                deleted: 'Deleted',
                state: {
                  change: 'Change state for {state}'
                }
              }
            }
          }
        }
      },
      jobOffer: {
        editOffer: 'Edit offer',
        newOffer: 'New offer',
        autoSaveDisabled: 'Autosave is disabled for published offer.',
        pleaseSelectTypeOfOffer: 'Please select type of offer.',
        continue: 'Continue',
        copyOfferAsNew: 'Use as template',
        publishNow: 'Publish',
        moveToArchive: 'Move to Archive',
        conclude_offer: 'Conclude',
        save: 'Save',
        schedule: 'Schedule',
        offerPreview: 'Offer preview',
        autoSaveOk: 'Auto save done.',
        autoSaveFailed: 'Auto save failed.',
        offerSaveOk: 'Offer saved.',
        offerSaveFailed: 'Offer saved failed.',
        offerAttachSaveOk: 'Attachments saved.',
        offerAttachSaveFailed: 'Saving attachments failed!',
        yesNoDialogPublishTitle: 'Publishing',
        yesNoDialogPublishMessage: 'Please confirm publishing.',
        yesNoDialogArchiveTitle: 'Archiving',
        yesNoDialogArchiveMessage: 'Please confirm archiving.',
        required: 'Required fields.'
      },
      validation: {
        url: 'Link to eRecruiter',
        urlSubject: 'URL address',
        dateOfDocumentsSubmissionDeadline: 'Date of Documents submission deadline',
        dateTermOfEmployment: 'Date of Term of employment',
        dateOfDocumentsSubmissionDeadlineStartSmallLetter: 'date of Documents submission deadline',
        dateOfExpirationDate: 'Expiration date'
      }
    },
    login: {
      title: 'Login',
      auth: {
        errors: {
          login: 'Invalid username or password!'
        }
      }
    },
    jobOffers: {
      headerForTeachers: 'Job offer for teacher',
      headerForMisc: 'Job offers for nonteachers',
      search: 'Search',
      buttons: {
        seeOffer: 'See details'
      },
      filters: {
        header: 'Filters',
        buttons: {
          clear: 'Clear',
          showFilters: 'Show filters',
          hideFilters: 'Hide filters',
          showMore: 'Show more',
          showLess: 'Show less'
        }
      }
    },
    competitionsResults: {
      header: 'Results',
      buttons: {
        downloadCompetitionResult: 'Download result'
      }
    },
    jobOffer: {
      labels: {
        summary: 'Summary'
      },
      buttons: {
        apply: 'Apply'
      }
    },
    contact: {
      address: 'Address',
      contactForm: 'Contact form',
      form: {
        fieldTopic: 'Topic',
        fieldEmail: 'Your email',
        fieldMsg: 'Message',
        sendMsg: 'Send message',
        somethingWrong: 'Something went wrong, please try again later.',
        msgSent: 'Message has been sent.',
        fieldAgreement: 'I agree.'
      },
      validation: {
        email: 'Your email address',
        emailSubject: 'email address'
      }
    },
    unauthorizedError: {
      header: 'Unauthorized!'
    },
    notFoundError: {
      header: 'Stone me! Tere is nothing here.'
    },
    siteUnderConstruction: {
      header: 'Site under construction.'
    },
    siteUnderMaintenance: {
      header: 'Site under maintenance.',
      goToOldOp: 'Go to current site'
    }
  },
  globals: {
    goBack: 'Go back',
    login: 'Login',
    accept: 'I accept',
    attachments: 'Attachments',
    cookies: {
      title: 'Cookies',
      info: 'Our site uses cookies. By continuing to browse the site you agree to our use of cookies in accordance with current browser settings. You can change at any time.',
      infoUrlText: ''
    },
    continueAnyway: 'Continue anyway',
    pwr: {
      info: {
        header: 'Wroclaw University of Science and Technology',
        line1: 'Wyspiańskiego 27 Coast',
        line2: '50-370 Wroclaw',
        line3: 'NIP: 896-000-58-51',
        line4: 'REGON: 000001614'
      },
      contact: {
        header: 'Contact',
        line1: 'Personal Affairs Department',
        line2: 'Recruitment, Training and Social Affairs Section',
        line3: 'e-mail:',
        phone: '71 320 4670'
      },
      help: {
        title: 'Help',
        email: 'pomoc+op@pwr.edu.pl'
      }
    }
  },
  validation: {
    invalid: 'Pole {field} jest nieprawidłowym {subject}.'
  },
  date: {
    today: 'today',
    now: 'now'
  },
  layouts: {
    pwr: {
      administration: {
        navbar: {
          actions: {
            home: 'Home',
            offers: 'Job offers',
            competitions: 'Results',
            users: 'Users and perms'
          }
        }
      },
      snackbar: {
        messages: {
          errors: {
            default: 'The operation was unsuccessful!'
          }
        }
      },
      navbar: {
        actions: {
          jobOffersForTeacher: 'Job offer for teacher',
          jobOffersForAdministration: 'Job offers for nonteachers',
          competitionsResults: 'Results',
          contact: 'Contact',
          administration: 'Administration'
        }
      }
    }
  },
  components: {
    pwr: {
      snackbar: {
        default: {
          titles: {
            default: '',
            success: 'Success!',
            error: 'Error!',
            info: 'Info!',
            warning: 'Warning!'
          }
        }
      }
    },
    op: {
      avatar: {
        logout: 'Sign out',
        role: 'Role'
      },
      textEditor: {
        placeholder: 'Write something here …',
        states: {
          init: 'Loading editor.',
          error: 'Unable to load editor! Try reloading the page.'
        },
        buttons: {
          default: 'Wartość domyślna'
        }
      },
      textViewer: {
        states: {
          init: 'Loading preview.',
          error: 'Unable to load preview! Try reloading the page.'
        }
      }
    },
    opNoResults: {
      message: 'No results'
    },
    dialogs: {
      yesNo: {
        buttons: {
          yes: 'Accept',
          no: 'Cancel'
        }
      }
    }
  },
  models: {
    offer: {
      scheduled: 'Scheduled'
    }
  },
  serviceWorker: {
    ready: 'App is being served from cache by a service worker.',
    registered: 'Service worker has been registered.',
    cached: 'Content has been cached for offline use.',
    updatefound: 'New content is downloading.',
    updated: 'New content is available; please refresh.',
    offline: 'No internet connection found. App is running in offline mode.',
    error: 'Unexpected error occurred while service worker registration.'
  },
  permissions: {
    noPermissions: 'No permissions to use this site!'
  }
};

export default en;
